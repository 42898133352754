footer {
    ul.footer-link-band-inner-container {
        display: flex;
        justify-content: space-between;
        //margin: 22px 0;
        margin: 0 0 22px 0;
        li {
            border-bottom: 1px solid $msv-gray-50;
            width: 28%;
            &:first-child {
                margin-left: 0;
            }
            a {
                color: $msv-white;
                font-size: 18px;
                padding: 8px 0;
                text-transform: uppercase;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    h5 {
        font-size: 10px;
        text-transform: uppercase;
        a {
            font-weight: 700;
        }
    }
    .ms-text-block {
        a {
            text-decoration: none;
        }
    }
}

footer ul.ms-footer__list.footer-security h5{
    font-weight: 700;
}

@media (max-width: $msv-breakpoint-l) {
    footer{
        ul.footer-link-band-inner-container{
            float: left;
            width: 100%;
            display: block;
            margin-bottom: 20px;
            li{
                width: 100%;
            }
        }        
        .post-footer-container{
            > .row{
                > div{
                    &:last-child{
                        .row{
                            > div{
                                &:last-child{
                                    ul.ms-footer__list{
                                        &.post-footer{
                                            padding-top: 0;
                                            display: block;

                                            > li.ms-footer__list-item.post-footer{
                                                margin-bottom: 30px;

                                                &:first-child{
                                                    margin-bottom: 24px;
                                                    min-width: 100%;
                                                    ul{
                                                        min-width: 100%;
                                                        display: block;

                                                        li{
                                                            min-width: 100%; 
                                                            text-align: center;
                                                        }
                                                    }
                                                }
                                                &:last-child{
                                                    margin: 0;
                                                    margin-bottom: 24px;
                                                    width: 100%;
                                                    ul{
                                                        width: 100%;
                                                        li{
                                                            width: 100%;
                                                            text-align: center;
                                                            h5{
                                                                width: 100%;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }                    
                }
            }
        }
    }

    footer ul.ms-footer__list.footer-security h5{
        font-weight: 700;    
    }
}