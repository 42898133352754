$msv-checkout-billing-address-margin: 4px;
$msv-checkout-input-margin-left: 0;
$msv-checkout-input-dimensions: 16px;
$msv-checkout-shipping-address-label-margin-bottom: 12px;
$msv-checkout-shipping-address-checkbox-margin-left: 8px;
$msv-checkout-address-detail-margin-left: 0;
$msv-checkout-address-detail-padding-bottom: 20px;

//style presets
:root {
    --msv-checkout-shipping-address-font-size: var(--msv-body-font-size-m);
    --msv-checkout-billing-address-font-size: var(--msv-body-font-size-m);
    --msv-checkout-shipping-address-font-color: #{$msv-gray-20};
    --msv-checkout-billing-address-font-color: var(--msv-font-primary-color);

    // heading
    --msv-checkout-billing-address-heading-font-color: #{$msv-gray-20};
    --msv-checkout-billing-address-heading-font-size: var(--msv-body-font-size-m);

    // secondary button
    --msv-checkout-shipping-address-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-checkout-shipping-address-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-checkout-shipping-address-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-checkout-billing-address {
    &__heading {
        @include font-heading-h5-l();
        color: var(--msv-checkout-billing-address-heading-font-color);
        margin-bottom: $msv-checkout-billing-address-margin;
    }

    .msc-address-detail {
        @include font-body-regular-m();
        color: var(--msv-checkout-shipping-address-font-color);
        margin-left: $msv-checkout-address-detail-margin-left;

        .msc-address-detail__item-address-detail_Phone {
            margin-top: 32px;
        }
    }

    &__shipping-address-label {
        display: flex;
        margin-bottom: $msv-checkout-shipping-address-label-margin-bottom;
        width: fit-content;
    }

    &__input {
        min-width: $msv-checkout-input-dimensions;
        min-height: $msv-checkout-input-dimensions;
        margin-left: $msv-checkout-input-margin-left;
    }

    &__shipping-address-checkbox-text {
        @include font-body-regular-m();
        margin-left: $msv-checkout-shipping-address-checkbox-margin-left;
        align-self: center;
    }
}

.ms-checkout-shipping-address {
    #checkout-address-edit-btn {
        display: inline-block;

        @media screen and (min-width: 560px) {
            display: none;
        }
    }

    #ms-checkout-street-warning {
        @include add-icon($msv-information, before);
        padding-top: 0;
        position: relative;
        font-size: 14px;
        color: #A80000;
        padding-left: 20px;
        text-transform: none;
        display: none;

        &:before {
            right: 5px;
            top: 2px;
            position: relative;
        }

        &.ms-warning-show {
            display: inline-block;
        }
    }

    .msc-address-select__button-add {
        @include secondary-button-light();
        text-transform: uppercase;
    }

    .msc-address-detail {
        @include font-body-regular-m();
        color: var(--msv-checkout-shipping-address-font-color);
        padding-bottom: $msv-checkout-address-detail-padding-bottom;
    }

    .msc-address-form__label,
    .msc-address-form__dropdown {
        color: var(--msv-checkout-address-font-color);
    }
    .msc-address-form__label {
        text-transform: uppercase;
    }
}

.ms-checkout__guided-card-footer {
    #checkout-address-edit-btn {
        display: none;

        @media screen and (min-width: 560px) {
            display: inline-block;
            order: 1;

            &.hide {
                display: none;
            }
        }
    }

    .ms-checkout__guided-card-btn-cancel {
        order: 2;
    }
}