.cirrus {
    .ms-tile-list .msc-ss-carousel .ms-tile__item {
        width: 25%;
        margin: 0;
        padding: 0 10px;

        .ms-tile-list-container {
            width: 100%;
        }
    }
}

.msc-address-form__street-info {
    font-size: 14px;
    color: #A80000;
    line-height: 1.3;
    font-weight: bold;
}

// Desktop view above 992px
@media (min-width: ($msv-breakpoint-l)) {
    .ms-refiner-heading {
        .ms-refiner-heading_title {
            button.ms-refine-filter__toggle_collapsed,
            .ms-refine-filter__toggle_expanded {
                font-size: 18px;
            }
        }
    }
    .ms-search-result-container__refiner-section {
        button.ms-refine-submenu__toggle_collapsed,
        .ms-refine-submenu__toggle_expanded {
            font-size: 18px;
        }
        .ms-refine-submenu.list-group {
            .multi-select,
            .multi-select-checked,
            .single-select,
            .single-select-checked {
                span.ms-refine-submenu-item__label {
                    font-size: 16px;
                }
            }
        }
    }
}

// mobile view $msv-breakpoint-m = 768px
@media screen and (max-width: $msv-breakpoint-m) {
    .cirrus {
        .ms-order-confirmation {
            .ms-order-confirmation {
                &__group-deliveryType-ship {
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
        }

        .msc-carousel {
            .ms-content-block[data-m-layout='full-width'] {
                .ms-content-block__details {
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;

                    .ms-content-block__title {
                        @include font-heading-h1-s();
                        text-align: center;
                    }

                    .ms-content-block__text {
                        text-align: center;

                        h3 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    #main {
        .msc-carousel__item {
            .hero {
                .ms-content-block__image {
                    // &:after {
                    //     border-bottom: 5px solid #fff;
                    //     background: rgba(0, 0, 0, 0.2);
                    // }
                }
            }
        }

        .ms-tile-list {
            .msc-ss-carousel .msc-flipper.msc-ss-carousel__flipper {
                border: 1px solid $msv-white;
            }
        }
    }

    .ms-header {
        .ms-header__container {
            .ms-header__topbar {
                justify-content: flex-start;
                > div {
                    flex-grow: 0;
                    &.desktop-navicon-container {
                        display: none;
                    }
                    &.mobile-navicon-container {
                        order: -1;
                        position: relative;
                        .ms-header__nav-icon {
                            height: 24px;
                            position: relative;
                            width: 21px;
                            &::before {
                                left: -2px;
                                position: absolute;
                            }
                        }
                    }
                    &.ms-header__logo {
                        flex-grow: 2;
                        margin-right: 0;
                        padding-left: 0;
                        img {
                            margin: 0 auto;
                        }
                    }
                    &.ms-header__mobile-logo {
                        display: none;
                    }
                }

            }
        }
    }

    .ms-nav {
        .ms-nav__list__item__button, 
        .ms-nav__list__item__link,
        .ms-nav__list__item__span {
            &:focus {
                color: $msv-black !important;
                background-color: $msv-white !important;
            }

            &:active {
                color: $msv-white !important;
                background-color: $msv-blue-1-custom !important;
            }
        }
    }

    .ms-cart {
        margin-top: 0;

        .msc-order-summary__checkout-button {
            margin-bottom: 40px;
        }
    }
}

// medium screen $msv-breakpoint-l = 992px
@media (max-width: ($msv-breakpoint-l)-1) {
    .ms-buybox {
        .ms-media-gallery.vertical {
            padding-top: 0;

            .ms-media-gallery__carousel {
                position: relative;
                top: 0;
            }
        }
    }

    .ms-search-result-container__refiner-section {
        .ms-refine-submenu.list-group {
            .ms-refine-submenu__list {
                &.Color,
                &.Size {
                    .ms-refine-submenu-item {
                        a {
                            &.multi-select-checked,
                            &.single-select-checked,
                            &.multi-select,
                            &.single-select {
                                span {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cirrus {
        .ms-tile-list .msc-ss-carousel .ms-tile__item {
            width: 33.33%;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .cirrus {
        .ms-tile-list .msc-ss-carousel .ms-tile__item {
            width: 50%;
        }
    }
}

@media (max-width: 450px) {
    .cirrus {
        .ms-tile-list .msc-ss-carousel .ms-tile__item {
            width: 100%;
        }
    }
}

.ms-checkout-guest-profile__add-contact-info {
    #ms-checkout-guest-profile__label {
        &:after {
            content: '*';
            width: 5px;
            height: 5px;
            padding-left: 7px;
        }
    }
}

.ms-interactive-feature-collection[data-m-layout="mosaic"] {
    // mobile view
    .ms-interactive-feature-collection-mobile {
        .msc-ss-carousel .ms-interactive-feature-collection-carousel .ms-interactive-feature-collection-carousel__container .ms-interactive-feature-collection-carousel__heading .ms-interactive-feature-collection {
            &__links {
                .ms-interactive-feature-collection__link {
                    background-color: transparent;
                }
            }
            &__title {
                text-align: center;
            }
        }
    }

    // desktop view
    .ms-interactive-feature-collection-desktop {
        .ms-interactive-feature-collection-mosaic__details .ms-interactive-feature-collection__links {
            .ms-interactive-feature-collection__link {
                background-color: transparent;
            }
        }
    }
}

.ms-cart-icon__minicartmodal-container {
    .ms-cart-icon__count {
        align-self: flex-end;
    }
}

.ms-order-details {
    .ms-order-details__group-delivery-heading {
        display: inline-block;
        margin-top: 20px;
    }
}

.ms-search-result-container__refine-product-section {
    .ms-search-result-container__refiner-section {
        .ms-refine-submenu.list-group {
            .ms-refine-submenu__list {
                &.Size {
                    .single-select,
                    .single-select-checked {
                        margin-bottom: 0px;

                        span.ms-refine-submenu-item__label {
                            font-size: 14px;
                            padding: 10px 12px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}