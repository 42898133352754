$ms-tile-list-full-width: 100%;
$ms-tile-list-full-height: 100%;
$ms-tile-list-carousel-margin-top: 15px;
$ms-tile-list-box-shadow-color: rgba(0, 0, 0, 0.132);
$ms-tile-list-heading-normal-color: rgba(0, 0, 0, 0.41);
$ms-tile-list-heading-contrast-color: rgba(0, 0, 0, 0);
$ms-tile-list-heading-shadow-color: rgba(0, 0, 0, 0.35);
$ms-tile-list-thumbnail_image-width: 50px;

$ms-tile-list-inline-height: 100%;
$ms-tile-list-letter-spacing: 0.01em;

// flipper
$msv-tile-list-flipper-position-mobile: -15px;
$msv-tile-list-flipper-position-tablet: -15px;
$msv-tile-list-flipper-position-desktop: -30px;

// links
$msv-tile-list-links-effect-position-top: 90%;
$msv-tile-list-links-effect-position-left: 10%;

// text
$msv-tile-list-text-effect-position-top: 87%;
$msv-tile-list-text-effect-position-left: 10%;

// title
$msv-tile-list-title-initial-position-top: 86.5%;
$msv-tile-list-title-initial-position-left: 30%;
$msv-tile-list-title-effect-position-top: 75%;
$msv-tile-list-title-effect-position-left: 10%;
$msv-tile-list-title-underline-bottom: -20px;

$msv-tile-list-thumbnail-image-initial-position-top: 82%;
$msv-tile-list-thumbnail-image-initial-position-left: 10%;

$msv-tile-list-transition-speed: 0.4s;
$msv-tile-list-transition-speed-slow: 0.6s;
$msv-tile-list-transition-speed-faster: 0.05s;

// Single tile width for desktop (s), tablet (m) and mobile (l).
$ms-tile-list__item-l: 328px;
$ms-tile-list__item-m: 348px;
$ms-tile-list__item-s: 315px;

$ms-tile-list__item-margin-right-desktop:20px;
$ms-tile-list__item-margin-right-ipad:24px;

//style presets
:root {
    //Links
    --msv-tile-list-link-font-color: #{$msv-white};
    --msv-tile-list-link-font-focus-color: #{$msv-gray-160};
    --msv-tile-list-link-font-size: var(--msv-body-font-size-s);

    //Text
    --msv-tile-list-text-font-color: var(--msv-font-secondary-color);
    --msv-tile-list-text-font-size: var(--msv-body-font-size-s);

    //Title
    --msv-tile-list-heading-font-color: var(--msv-font-primary-color);
    --msv-tile-list-title-font-color: var(--msv-font-secondary-color);
    --msv-tile-list-title-underline-color: #{$msv-blue-8-custom};
}

@mixin tile-list-effect {

    transform: scale(1.0);
    transition: transform $msv-tile-list-transition-speed;

    .ms-tile-list__thumbnail_image {
        top: calc(65% - 20px);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0);
        -webkit-transform: translateZ(0);
    }

    .ms-tile-list__heading {
        background-color: $ms-tile-list-heading-shadow-color;

        .ms-tile-list__title {
            //@include font-heading-h3-l();
            //border-top: 1px solid $msv-blue-8-custom;
            //border-top: none;
            //left: $msv-tile-list-title-effect-position-left;
            //left: 0;
            //top: unset;
            //width: $ms-tile-list-full-width; //auto
            //max-width: $ms-tile-list-full-width; //85%
            //transition: all $msv-tile-list-transition-speed;
            //transition-delay: $msv-tile-list-transition-speed-faster;

            // &::after {
            //     transform: scaleX(1);
            //     transition: transform $msv-tile-list-transition-speed-slow;
            //     transition-delay: $msv-tile-list-transition-speed-faster;
            // }

            &::after{
                position: absolute;
                display: none;
                bottom: 0;
                content: "";
                border-bottom: 1px solid $msv-white;
                border-bottom: none;
                width: $ms-tile-list-full-width;
            }
        }

        .ms-tile-list__text {
            top: $msv-tile-list-text-effect-position-top;
            left: $msv-tile-list-text-effect-position-left;
            position: absolute;
            display: inline;
            opacity: 1;

            p {
                letter-spacing: $ms-tile-list-letter-spacing;
                line-height: $ms-tile-list-inline-height;
                color: var(--msv-tile-list-text-font-color);
                font-size: var(--msv-tile-list-text-font-size);
                visibility: visible;
                opacity: 1;
            }
        }

        .ms-tile-list__links {
            top: $msv-tile-list-links-effect-position-top;
            left: $msv-tile-list-links-effect-position-left;
            position: absolute;
            display: inline;
            opacity: 1;

            .ms-tile-list__link {
                color: var(--msv-tile-list-link-font-color);
                font-size: var(--msv-tile-list-link-font-size);
                visibility: visible;
                outline: none;
                opacity: 1;
            }
        }
    }
}

.ms-tile-list {
    &__heading {
        .ms-tile-list__title {
            @include font-heading-h5-m();
            text-transform: uppercase;
            color: var(--msv-tile-list-heading-font-color);
            margin-bottom: 20px;
            text-align: center;
            font-size: 40px;
            font-family: "Akzidenz-Light", sans-serif;
        }
    }

    .msc-ss-carousel {
        margin-top: $ms-tile-list-carousel-margin-top;

        .msc-tooltip {
            visibility: hidden;
        }

        .msc-flipper {
            &.msc-ss-carousel__flipper {
                top: 50%;
                left: -10px;
                cursor: pointer;

                &.disabled {
                    visibility: hidden;
                }

                @media screen and (max-width: $msv-breakpoint-m) {
                    left: $msv-tile-list-flipper-position-mobile;
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    left: $msv-tile-list-flipper-position-tablet;
                }

                @media screen and (min-width: $msv-breakpoint-l) {
                    left: $msv-tile-list-flipper-position-desktop;
                }

                &:active {
                    transform: translate(0, -50%) scale(0.9);
                    transition: all .3s ease;
                }

                .msi-chevron-left,
                .msi-chevron-right {
                    @include chevron-mobile();
                    transform: $chevron-translate-mobile;

                    @media screen and (min-width: $msv-breakpoint-m) {
                        width: $chevron-width-desktop;
                        transform: $chevron-translate-desktop;
                    }
                }


            }

            &.msc-ss-carousel__flipper--next {
                right: -10px;
                left: auto;
                cursor: pointer;

                @media screen and (max-width: $msv-breakpoint-m) {
                    right: $msv-tile-list-flipper-position-mobile;
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    right: $msv-tile-list-flipper-position-tablet;
                }

                @media screen and (min-width: $msv-breakpoint-l) {
                    right: $msv-tile-list-flipper-position-desktop;
                }
            }
        }

        .ms-tile__item {
            display: inline-block;
            max-width: none;
            overflow: hidden;
            text-align: left;
            vertical-align: top;
            transition: transform $msv-tile-list-transition-speed;
            position: relative;
            margin-left: 20px;
            margin-right: 0;

            &:first-child {
                margin-left: 0;
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                width: $ms-tile-list__item-l;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                width: $ms-tile-list__item-m;
                margin-right: $ms-tile-list__item-margin-right-ipad;
            }

            @media screen and (min-width: $msv-breakpoint-l) {
                width: $ms-tile-list__item-s;
            }

            .ms-tile-list__heading {
                width: $ms-tile-list-full-width;
                height: $ms-tile-list-full-height;
                text-align: center;
                position: absolute;
                cursor: pointer;
                //background-color: $ms-tile-list-heading-normal-color;
                opacity: 1;
                top: 0;
                left: 0;
                bottom: 0;
                background: rgb(0,0,0);
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7234244039412641) 100%);

                @media screen and (-ms-high-contrast: active) {
                    background-color: $ms-tile-list-heading-contrast-color;
                }

                .ms-tile-list__title {
                    @include font-heading-h4-m();
                    font-family: $msv-font-family-akzidenz-light;
                    font-size: 24px;
                    text-shadow: 0 6.4px 14.4px $ms-tile-list-box-shadow-color;
                    letter-spacing: $ms-tile-list-letter-spacing;
                    color: var(--msv-tile-list-title-font-color);
                    top: unset;
                    bottom: 0;
                    //left: $msv-tile-list-title-initial-position-left;
                    left: 0;
                    justify-content: center; //left
                    flex-direction: row;
                    position: absolute;
                    display: flex;
                    text-align: center; //left
                    white-space: break-spaces;
                    width: $ms-tile-list-full-width; //auto
                    max-width: $ms-tile-list-full-width; //65%

                    // &::after {
                    //     border-bottom: 2px solid var(--msv-tile-list-title-underline-color);
                    //     transition: all 0s ease-out 0s;
                    //     width: $ms-tile-list-full-width;
                    //     transform: scaleX(0);
                    //     position: absolute;
                    //     display: block;
                    //     bottom: $msv-tile-list-title-underline-bottom;
                    //     content: "";
                    // }
                }
            }

            .ms-tile-list__thumbnail_image {
                transition: $msv-tile-list-transition-speed;
                top: $msv-tile-list-thumbnail-image-initial-position-top;
                left: $msv-tile-list-thumbnail-image-initial-position-left;
                position: absolute;
                cursor: pointer;
                width: $ms-tile-list-thumbnail_image-width;
                display: none; //block
                margin: 0 auto;

                @include image($ms-tile-list-thumbnail_image-width);
            }

            .ms-tile-list__image {
                width: $ms-tile-list-full-width;

                @include image($ms-tile-list-full-width);

                picture > img {
                    border-radius: 2%;
                    transition: transform $msv-tile-list-transition-speed;
                }
            }

            .ms-tile-list__link {
                width: $ms-tile-list-full-width;

                &:focus, &:focus-within {
                    outline: 1px solid var(--msv-tile-list-link-font-focus-color);
                }
            }

            .ms-tile-list__text {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity $msv-tile-list-transition-speed-faster linear;
                transition-delay: $msv-tile-list-transition-speed-faster;
            }

            .ms-tile-list__links {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity $msv-tile-list-transition-speed-faster linear;
                transition-delay: $msv-tile-list-transition-speed-faster;
            }

            .ms-tile-list-container {
                width: $ms-tile-list-full-width;
                display: inline-block;
                vertical-align: top;
                position: relative;
                border: none;
                outline: none;
                padding: inherit;
                max-width: none;
                overflow: hidden;
                text-align: left;
                border-radius: 3%;

                @media screen and (max-width: $msv-breakpoint-m) {
                    width: $ms-tile-list__item-l;
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    width: $ms-tile-list__item-m;
                }

                @media screen and (min-width: $msv-breakpoint-l) {
                    width: $ms-tile-list__item-s;
                }

                &:hover {
                    @include tile-list-effect;
                }

                &:focus-visible {
                    @include tile-list-effect;
                }

                @media not all and (min-resolution: 0.001dpcm) {
                    @supports (-webkit-appearance:none) {
                        &:focus {
                            @include tile-list-effect;
                        }
                    }
                }
            }
        }
    }
}

.ms-tile-list.b2b-tiles {
    .msc-ss-carousel .ms-tile__item {
        .ms-tile-list-container {
            .ms-tile-list__heading .ms-tile-list__title {
                position: relative;
                justify-content: center;
                left: auto;
            }

            &:hover {
                .ms-tile-list__heading .ms-tile-list__title {
                    position: absolute;
                    left: 0; //10%
                }
            }

            &:focus-visible {
                .ms-tile-list__heading .ms-tile-list__title {
                    position: absolute;
                    left: 0; //10%
                }
            }

            @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance:none) {
                    &:focus {
                        .ms-tile-list__heading .ms-tile-list__title {
                            position: absolute;
                            left: 10%;
                        }
                    }
                }
            }
        }
    }
}
.last-viewed {
    //padding-top: 50px;
    width: 100%;

    h3 {
        font-size: 40px;
        width: 100%;
        text-align: center;
    }

    .image-container-last-viewed {
        flex-direction: column;

        ul {
            display: block;
            padding: 0;

            li {
                float: left;
                height: auto;                
                width: 25%;
                list-style-type: none;
                padding: 0 10px;
                box-sizing: border-box;

                a {
                    float: left;
                    width: 100%;
                    height: auto;
                    position: relative;
                    display: table;


                    img {
                        display: table-cell;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 250px;
                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    span {
                        @include font-heading-h5-s;
                        color: black;
                        float: left;
                        width: 100%;
                        height: auto;
                        display: table-cell;
                        position: relative;
                        top: unset;
                        text-align: center;
                        padding: 15px;;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $msv-breakpoint-l) {
    .last-viewed h3 {
        font-size: 30px;
    }
    .last-viewed .image-container-last-viewed ul {
        display: block;
        padding: 0;
    }

    .last-viewed .image-container-last-viewed ul li {
        float: left;
        width: 50%;
        height: auto;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .last-viewed h3 {
        font-size: 30px;
    }
    .last-viewed .image-container-last-viewed ul {
        display: block;
        padding: 0;
        width: 100%;
        float: left;
        height: auto;
    }

    .last-viewed .image-container-last-viewed ul li {
        clear: both;
        height: auto;
        min-width: 100%;
        margin-bottom: 20px;

        a {
            img {
                max-height: 150px;
            }
        }
    }

    .last-viewed .image-container-last-viewed ul a {
        float: left;
        width: 100%;
        height: auto;
        position: relative;
        display: table;
    }

    .last-viewed .image-container-last-viewed ul li a span {
        float: left;
        width: 100%;
        height: auto;
        display: table-cell;
        position: relative;
        top: unset;
        color: $msv-black;
    }
}