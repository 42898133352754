$navigation-parent-menu-margin-left: 20px;
$navigation-parent-menu-margin-right: 20px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-menu-item-line-height: 28px;
$navigation-menu-item-level2-line-height: 24px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-padding: 40px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 15px;
$navigation-ms-nav-area-width: 63%;
$navigation-menu-list-min-width: 180px;
$navigation-ms-nav-list-item-link-margin-bottom: 20px;

$navigation-ms-nav-list-mobile-container-padding-left: 16px;
$navigation-ms-nav-list-mobile-container-padding-right: 34px;
$navigation-ms-nav-list-button-mobile-padding-left: 0;
$navigation-ms-nav-list-button-mobile-padding-right: 0;
$navigation-ms-nav-list-button-before-mobile-font-size: 21px;
$navigation-ms-nav-list-item-link-mobile-margin-top: 0px;
$navigation-ms-nav-list-item-link-mobile-padding-top: 16px;
$navigation-ms-nav-list-item-link-mobile-padding: 16px;
$navigation-ms-nav-list-feature-mobile-padding-bottom: 20px;
$navigation-ms-nav-child-mobile-padding-top: 12px;

$navigation-ms-nav-list-tablet-margin-left: 0;
$navigation-ms-nav-list-item-tablet-margin-left: 0;
$navigation-ms-nav-list-level3-tablet-margin-left: 20px;
$navigation-ms-nav-ms-nav-list-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-link-level2-tablet-margin-bottom: 20px; //25px
$navigation-ms-nav-list-item-link-level3-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-tablet-first-child-margin-left: 0;
$navigation-ms-nav-close-button-tablet-padding-right: 16px;
$navigation-ms-nav-close-button-tablet-padding-top: 10px;
$navigation-ms-nav-list-item-link-button-tablet-margin-bottom: 28px;
$navigation-ms-nav-list-item-nav-feature-tablet-padding-left: 24px;
$navigation-ms-nav-list-item-drawer-button-tablet-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-tablet-padding-left: 40px;
$navigation-ms-nav-list-item-link-tablet-padding-zero: 0;
$navigation-ms-nav-list-item-button-first-child-tablet-margin-left: 0;
$navigation-ms-nav-drawer-open-close-tablet-padding: 13px;

$navigation-ms-nav-list-desktop-margin-left: 0;
$navigation-ms-nav-list-item-desktop-margin-left: 0;
$navigation-ms-nav-list-level2-desktop-margin-left: 20px;
$navigation-ms-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-link-desktop-margin-bottom: 20px; //25px
$navigation-ms-nav-list-item-link-level2-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-button-link-desktop-margin-bottom: 40px;
$navigation-ms-nav-list-item-first-child-desktop-margin-left: 0;
$navigation-ms-nav-default-nav-list-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-left: 60px;
$navigation-ms-nav-list-item-nav-feature-desktop-padding-right: 60px;
$navigation-ms-nav-list-item-levle2-desktop-padding-right: 20px;
$navigation-ms-nav-list-item-drawer-button-desktop-margin-bottom: 15px;
$navigation-ms-nav-list-item-drawer-collapse-desktop-padding-left: 40px;
$navigation-ms-nav-list-item-link-desktop-padding-left: 0;
$navigation-ms-nav-list-item-link-desktop-padding-right: 0;
$navigation-ms-nav-list-item-button-first-child-desktop-margin-left: 0;
$navigation-ms-nav-drawer-open-desktop-padding-right: 13px;
$navigation-ms-nav-drawer-close-desktop-padding-right: 13px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-right: 20px;
$navigation-ms-nav-list-item-link-disable-top-menu-padding-bottom: 20px; //25px

$msv-width-onequarter: 25%;

//style presets
:root {
    --msv-nav-bg: var(--msv-accent-brand-color);
    --msv-nav-border: transparent;
    --msv-nav-dropdown-border: transparent;
    --msv-nav-carot-color: var(--msv-font-secondary-color);
    --msv-nav-font-color: var(--msv-font-secondary-color);
    --msv-nav-font-size: var(--msv-body-font-size-m);
    --msv-nav-close-icon-font-size: #{$msv-icon-size-xs};
}

.ms-nav {
    background: var(--msv-nav-bg);
    color: var(--msv-nav-font-color);
    width: $msv-width-full;

    &__list {
        z-index: 1000;

        &.level-2 {
            width: $msv-width-full;
        }

        .level-2 {
            > li {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);
            }

            &.navmenu-multi-level {
                li.ms-nav__list__item {
                    .havesubmenu {
                        font-weight: normal;
                    }
                }
            }
        }

        &__item {
            @include font-body-regular-m();
            color: var(--msv-nav-font-color);

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .havesubmenu {
                @include font-body-regular-m();
                color: var(--msv-nav-font-color);
            }

            .level-3 {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .havesubmenu {
                    .ms-nav__list {
                        margin-left: $navigation-ms-nav-submenu-margin-left;
                    }
                }
            }

            &__image {
                position: absolute;
                right: 0;
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    > .ms-nav__list {
        background: var(--msv-nav-bg);

        > .ms-nav__list__item {
            background: var(--msv-nav-bg);

            > .ms-nav__list {
                background: var(--msv-nav-bg);
                min-width: $navigation-menu-list-min-width;
            }

            .ms-nav__deafult {
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                }
            }

            > .ms-nav__deafult {
                left: 0;
            }

            > .ms-nav__list__item__button {
                @media screen and (max-width: 500px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 320px) {
                    font-size: 15px;
                }
            }

            > .ms-nav__feature {
                background: var(--msv-nav-bg);
                border: 1px solid var(--msv-nav-dropdown-border);
                height: auto;
                left: $navigation-menu-wrapper-left;
                min-width: $navigation-menu-list-min-width;
                position: absolute;
                outline: none;
                overflow: auto;
                width: $navigation-menu-wrapper-width;
                display: inline-flex;
                gap: 5%;

                > .category-image-container {
                    .category-image {
                        position: relative;
                        // border: 3px solid red;

                        .category-image-title {
                            text-align: center;
                            display: none;
                        }

                        picture {
                            + div.category-image-title {
                                display: block;
                            }
                        }

                        .ms-nav__list__item__link {
                            @include feature-link-dark-m();
                            text-align: center;
                            word-break: break-all;
                            white-space: break-spaces;
                            height: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            padding: 0;
                            border-bottom: 3px solid $msv-blue;
                            transform: translate(-50%, -50%);
                            background-color: transparent;
                        }

                        img {
                            margin: 0 auto;
                            height: 100%;
                            object-fit: cover;
                            transition: unset;

                            > .ms-nav-image__item {
                                display: block;
                                padding: $navigation-menu-image-padding;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-xs) {
    .ms-nav {
        &__list {
            display: block;
            flex-wrap: nowrap;

            &__mobile__container {
                border-bottom: 1px solid var(--msv-nav-border);
                display: flex;
                padding-left: $navigation-ms-nav-list-mobile-container-padding-left;
                padding-right: $navigation-ms-nav-list-mobile-container-padding-right;
                height: 50px;
                background: var(--msv-nav-bg);
                align-items: center;

                &__button {
                    @include vfi();
                    @include add-icon($msv-BackArrow);
                    @include font-body-regular-m();
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    padding-left: $navigation-ms-nav-list-button-mobile-padding-left;
                    padding-right: $navigation-ms-nav-list-button-mobile-padding-right;

                    &:focus {
                        outline-offset: -1px;
                    }
                }

                &__button::before {
                    font-size: $navigation-ms-nav-list-button-before-mobile-font-size;
                }

                &__span {
                    @include font-body-bold-m();
                    margin: 0 auto;
                    color: var(--msv-nav-font-color);
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                &__item {
                    &__image {
                        display: none;
                    }

                    &__button,
                    &__link,
                    &__span {
                        @include font-heading-h4-s();
                        font-family: $msv-font-family-akzidenz-light;
                        display: flex;
                        align-items: center;
                        color: $msv-black; //var(--msv-header-font-color)
                        height: auto;

                        @media screen and (max-width: ($msv-breakpoint-m)-1) {
                            margin-top: $navigation-ms-nav-list-item-link-mobile-margin-top;
                            width: 100%;
                            padding: $navigation-ms-nav-list-item-link-mobile-padding;
                        }
                        background-color: $msv-white; //var(--msv-nav-bg)
                        border: none;
                        padding: 0;

                        &::after {
                            // display: none;
                        }

                        &:focus,
                        &:active {
                            @include font-heading-h3-s();
                            font-family: $msv-font-family-akzidenz-light;
                            outline: none;
                            color: $msv-white; //var(--msv-nav-font-color);
                            background-color: $msv-black; //$msv-gray-70;

                            @media screen and (max-width: 500px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 320px) {
                                font-size: 15px;
                            }
                        }
                        &:active {
                            color: $msv-white;
                            background-color: $msv-blue-1-custom;
                        }
                    }

                    .ms-nav__list {
                        .ms-nav__list__item__button {
                            justify-content: space-between;

                            @media screen and (max-width: ($msv-breakpoint-m)-1) {
                                @include add-icon($msv-ChevronRight, after);
                                float: right;
                            }
                        }

                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link {
                            @include font-heading-h4-s();
                            font-family: $msv-font-family-akzidenz-light;
                            color: $msv-black; //var(--msv-nav-font-color);

                            @media screen and (max-width: 500px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 320px) {
                                font-size: 15px;
                            }

                            &:focus,
                            &:active {
                                @include font-heading-h3-s();
                                font-family: $msv-font-family-akzidenz-light;
                                color: $msv-white; //var(--msv-nav-font-color);
                                outline: none;
                                background-color: $msv-black; //$msv-gray-70;

                                @media screen and (max-width: 500px) {
                                    font-size: 16px;
                                }
                                @media screen and (max-width: 320px) {
                                    font-size: 15px;
                                }
                            }
                            &:active {
                                color: $msv-white;
                                background-color: $msv-blue-1-custom;
                            }
                        }
                    }

                    .ms-nav__list.level-3 {
                        .ms-nav__list__item__button {
                            justify-content: space-between;

                            @media screen and (max-width: ($msv-breakpoint-m)-1) {
                                @include add-icon($msv-ChevronRight, after);
                                float: right;
                            }
                        }

                        .ms-nav__list__item__button,
                        .ms-nav__list__item__link {
                            @include font-heading-h4-s();

                            &:focus,
                            &:active {
                                @include font-heading-h3-s();
                                outline: none;
                                color: $msv-white; //var(--msv-nav-font-color);
                                background-color: $msv-black; //$msv-gray-70;
                            }
                            &:active {
                                color: $msv-white;
                                background-color: $msv-blue-1-custom;
                            }
                        }
                    }
                }
            }
        }

        > .ms-nav__list {
            width: $msv-width-full;
            outline: none;
            float: left;
            min-height: unset;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 30px;
                left: 0;
                width: 100%;
                height: calc(100% - 30px);
                background: $msv-white;
                z-index: -1;
            }

            .ms-nav__list {
                width: $msv-width-full;
            }

            .havecateImage {
                float: left;
                position: relative;
                width: $navigation-ms-nav-area-width;
            }

            > .ms-nav__list__item {
                .ms-nav__feature {
                    padding-bottom: $navigation-ms-nav-list-feature-mobile-padding-bottom;
                    width: $msv-width-full;
                }

                > .ms-nav__list__item__button {
                    color: $msv-black; //var(--msv-nav-font-color);
                    justify-content: space-between;
                    text-align: left;
                    text-transform: uppercase;

                    &:focus {
                        color: $msv-white;
                        @media screen and (max-width: ($msv-breakpoint-m)-1) {
                            @include add-icon($msv-ChevronRight, after);
                            float: right;
                            color: $msv-white;
                        }
                    }

                    @media screen and (max-width: ($msv-breakpoint-m)-1) {
                        @include add-icon($msv-ChevronRight, after);
                        float: right;
                        color: $msv-black;
                    }
                }
            }

            .ms-nav__list__item:first-child {
                @media screen and (max-width: ($msv-breakpoint-m)-1) {
                    padding-top: $navigation-ms-nav-list-item-link-mobile-padding-top;
                    padding-top: 0;
                }
            }
        }
    }

    .ms-nav.child {
        position: absolute;
        top: 0;
        padding-top: $navigation-ms-nav-child-mobile-padding-top;
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
    .ms-nav {
        display: flex;
        position: relative;
        border-top: 1px solid var(--msv-header-font-color);
        ul.ms-nav__list {
            &::after {
                display: none;
            }
        }

        &.main-nav-header {
            ul.ms-nav__list {
                li.ms-nav__list__item {
                    button.ms-nav__list__item__button {
                        font-size: 24px !important;
                    }
                    .ms-nav__feature {
                        ul.ms-nav__list {
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            width: 100%;

                            li.ms-nav__list__item {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        &__list {
            position: absolute;

            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                background: var(--msv-nav-bg);
                width: $msv-width-threequarter;

                .ms-nav__list {
                    position: relative;
                    margin-left: $navigation-ms-nav-list-tablet-margin-left;

                    .ms-nav__list__item {
                        font-weight: normal;
                        margin-left: $navigation-ms-nav-list-item-tablet-margin-left;

                        .ms-nav__list {
                            margin-left: $navigation-ms-nav-list-level3-tablet-margin-left;
                        }
                    }
                }

                .level-2 {
                    display: block;
                }
            }

            &__mobile__container {
                display: none;
            }
        }

        .category-image-container {
            width: 35%;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        > .ms-nav__list {
            display: flex;
            flex-wrap: wrap;
            outline: none;
            position: relative;
            float: none;
            justify-content: center;
            width: 100%;
            padding: 0 8%;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .ms-nav__list {
                height: auto;

                .ms-nav__list__item__link {
                    @include font-body-regular-m();
                    color: var(--msv-nav-font-color);
                    margin-bottom: $navigation-ms-nav-list-item-link-level2-tablet-margin-bottom;
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 0.5px;
                        bottom: -8px !important;
                        left: 0;
                        background-color: $msv-white;
                        visibility: visible;
                        transition: unset;
                        display: none;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list {
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-bottom: $navigation-ms-nav-list-item-link-level3-tablet-margin-bottom;
                        border-bottom: none;

                        &::before {
                            visibility: hidden;
                        }

                        &:hover::before {
                            visibility: hidden;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            > .ms-nav__list__item:first-child {
                margin-left: $navigation-ms-nav-list-item-tablet-first-child-margin-left;
            }

            .ms-nav-close-button {
                position: absolute;
                right: 20px;
                padding-right: $navigation-ms-nav-close-button-tablet-padding-right;
                padding-top: $navigation-ms-nav-close-button-tablet-padding-top;

                @media screen and (min-width: $msv-breakpoint-m) {
                    padding-top: 63px;
                    padding-right: 0;
                }

                &.hide {
                    display: none;
                }

                .nav-menu-close {
                    color: var(--msv-nav-font-color);
                    background-color: transparent;
                    border: 1px solid transparent;

                    @include vfi();

                    &::before {
                        @include msv-icon();
                        content: $msv-Cancel;
                        font-size: var(--msv-nav-close-icon-font-size);
                        position: unset;
                        text-align: center;
                        vertical-align: text-bottom;
                    }
                }
            }

            > .ms-nav__list li:has(.ms-nav__feature) {
                .ms-nav-close-button {
                    display: block;
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list__item {
                margin: {
                    top: $navigation-parent-menu-margin-top;
                    left: $navigation-parent-menu-margin-left;
                    right: $navigation-parent-menu-margin-right;
                }

                &:nth-of-type(4),
                &:nth-of-type(6) {
                    .ms-nav__feature .ms-nav__list.level-2 {
                        columns: 3;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button,
                > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-body-regular-m();
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: none;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    justify-content: center;
                    position: relative;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin-bottom: $navigation-ms-nav-list-item-link-button-tablet-margin-bottom;
                }

                > .ms-nav__list__item__button[aria-expanded='true'],
                > .ms-nav__list__item__link[aria-expanded='true'] {
                    @include font-heading-h3-m();
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button {
                    &::after {
                        @include msv-icon();
                        color: var(--msv-nav-carot-color);
                        content: $msv-ChevronDown;
                        font-size: var(--msv-nav-font-size);
                        margin-left: $navigation-menu-button-icon-margin-left;
                        line-height: $navigation-menu-icon-line-height;
                        display: none;
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .ms-nav__list__item__link {
                    color: var(--msv-nav-font-color);
                    justify-content: flex-start;
                    width: auto;
                    display: inline-block;
                }

                > .ms-nav__feature,
                > .ms-nav__deafult {
                    > .ms-nav__list {
                        columns: 2;
                        display: block;

                        > .ms-nav__list__item {
                            break-inside: avoid;

                            > a {
                                text-transform: uppercase;
                            }

                            > .ms-nav__list__item__link {
                                @include feature-link-dark-m();
                            }

                            > .ms-nav__list {
                                break-after: column;
                            }
                        }
                    }
                }

                > .ms-nav__deafult {
                    > .ms-nav__list {
                        width: $msv-width-full;
                        padding-left: $navigation-ms-nav-list-item-nav-feature-tablet-padding-left;

                        > .ms-nav__list__item {
                            padding-bottom: 25px;
                        }
                    }
                }

                .ms-nav__feature {
                    display: inline-flex;
                    padding: 3% 6% 5%;
                    top: 100%;
                }

                .ms-nav__feature,
                .ms-nav__deafult {
                    .ms-nav__list.level-3 {
                        .ms-nav__list__item {
                            .ms-nav__drawer_desktop.level-3 {
                                .drawer__button {
                                    border: none;
                                    padding: 0;
                                    margin-bottom: $navigation-ms-nav-list-item-drawer-button-tablet-margin-bottom;
                                    display: inline-flex;
                                    text-align: left;

                                    div {
                                        display: contents;
                                    }

                                    .drawer__buttontext {
                                        @include font-body-regular-m();
                                        color: var(--msv-nav-font-color);
                                    }
                                }

                                .drawer__button:focus {
                                    outline: none;
                                }

                                .drawer__button:focus-visible {
                                    outline: none;
                                    border: 1px solid $msv-white;
                                }

                                .collapse.show,
                                .collapsing {
                                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                                    div {
                                        display: grid;
                                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                                        .ms-nav__list__item__link {
                                            cursor: pointer;
                                            padding-left: $navigation-ms-nav-list-item-drawer-collapse-tablet-padding-left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__link {
                    display: flex;
                    padding-left: $navigation-ms-nav-list-item-link-tablet-padding-zero;
                    padding-right: $navigation-ms-nav-list-item-link-tablet-padding-zero;
                    justify-content: center;
                    line-height: $navigation-menu-item-line-height;
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &:hover,
                    &:focus {
                        background: transparent;
                        text-decoration: none;
                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        &::before {
                            width: $msv-width-full;
                        }
                    }
                }

                > .ms-nav__list__item__button:first-child {
                    margin-left: $navigation-ms-nav-list-item-button-first-child-tablet-margin-left;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list {
                    border: 1px solid var(--msv-nav-dropdown-border);
                    box-shadow: 0 8px 15px var(--msv-nav-font-color);
                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    .ms-nav__list__item__link {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                    }
                }
            }
        }

        &.ms-nav__disable-top-menu {
            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            > .ms-nav__list > .ms-nav__list__item {
                font-family: 'Akzidenz-Light';
                margin: 15px 0 15px 25px;

                &:first-of-type {
                    margin-left: 0;
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                > .ms-nav__list__item__button,
                > .ms-nav__list__item__link {
                    @include vfi();
                    @include font-heading-h4-m();
                    align-items: flex-end;
                    background-color: var(--msv-nav-bg);
                    border: 1px solid transparent;
                    color: var(--msv-nav-font-color);
                    display: flex;
                    flex: none;
                    flex-grow: 0;
                    font-family: 'Akzidenz-Light';
                    font-size: 14px;
                    letter-spacing: 1.25px;
                    min-height: 1px;
                    justify-content: center;
                    line-height: 1;
                    margin-bottom: 0;
                    order: 0;
                    position: relative;
                    text-transform: uppercase;
                    white-space: nowrap;
                }

                > .ms-nav__list__item__button[aria-expanded='true'],
                > .ms-nav__list__item__link[aria-expanded='true'] {
                    @include font-heading-h3-m();
                    font-size: 14px;
                    font-family: 'Akzidenz-Light';
                    border-bottom: none;
                    text-transform: uppercase;

                    &:hover {
                        font-family: 'Akzidenz-Light';
                        border-bottom: none;
                    }
                }

                > .ms-nav__feature {
                    > .ms-nav__list > .ms-nav__list__item {
                        padding-right: 0;
                        padding-bottom: 30px;

                        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                        .ms-nav__list__item__link {
                            @include feature-link-m();
                            font-size: 14px;
                            font-weight: 400;
                            font-family: 'Akzidenz-Light';
                            height: auto;
                            letter-spacing: normal;
                            border: 1px solid black !important;
                            white-space: nowrap;
                            margin-bottom: 5px;

                            &::before {
                                border-radius: 0;
                            }
                        }

                        &.havesubmenu .ms-nav__deafult {
                            .ms-nav__list {
                                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                                .ms-nav__list__item__link {
                                    @include font-body-regular-m();
                                    color: var(--msv-nav-font-color);
                                    margin-bottom: $navigation-ms-nav-list-item-link-level2-desktop-margin-bottom;
                                    border-bottom: none;

                                    @include vfi();

                                    &::before {
                                        visibility: hidden;
                                    }

                                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                                    &:hover {
                                        text-decoration: underline;
                                    }

                                    &:hover::before {
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ms-nav__drawer-open {
        @include add-icon($msv-ChevronUp, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-close-tablet-padding;

        &::after {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }
    }

    .ms-nav__drawer-close {
        @include add-icon($msv-ChevronDown, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-close-tablet-padding;

        &::after {
            font-size: $msv-icon-size-m;
            vertical-align: middle;
        }
    }

    .drawer__glyph:focus {
        outline: none;
    }

    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            bottom: -6px;
            width: 100%;
            left: 0;
            height: 0px;
            background-color: transparent;
            transition: color 0.3s ease-in-out, height 0.3s ease-in-out;
        }

        &:hover,
        &:focus,
        &[aria-expanded='true'] {
            &:before {
                height: 1px;
                background-color: $msv-blue;
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .ms-nav {
        &.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item {
            margin-left: 11px;
        }

        & > .ms-nav__list > .ms-nav__list__item .ms-nav__feature {
            padding: 3% 15% 3%;
        }

        &__list {
            position: absolute;

            &.level-2 {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: 0;
                background: var(--msv-nav-bg);
                width: 80%;

                .ms-nav__list {
                    position: relative;
                    margin-left: $navigation-ms-nav-list-desktop-margin-left;

                    .ms-nav__list__item {
                        @include font-body-regular-m();
                        color: var(--msv-nav-font-color);
                        margin-left: $navigation-ms-nav-list-item-desktop-margin-left;
                        text-align: left;
                        .ms-nav__list {
                            margin-left: $navigation-ms-nav-list-level2-desktop-margin-left;
                        }
                    }
                }

                .level-2 {
                    display: block;
                }
            }

            &__mobile__container {
                display: none;
            }
        }
    }

    .ms-nav__drawer-open {
        @include add-icon($msv-ChevronUp, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-open-desktop-padding-right;
    }

    .ms-nav__drawer-close {
        @include add-icon($msv-ChevronDown, after);
        color: var(--msv-nav-font-color);
        padding-right: $navigation-ms-nav-drawer-close-desktop-padding-right;
    }

    .drawer__glyph:focus {
        outline: none;
    }
}

@media screen and (min-width: 1000px) {
    .ms-nav {
        > .ms-nav__list {
            padding: 0;
        }
    }
}

@media screen and (min-width: $msv-breakpoint-l2) {
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded='true'],
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link[aria-expanded='true'],
    .ms-nav.ms-nav__disable-top-menu
        > .ms-nav__list
        > .ms-nav__list__item
        > .ms-nav__feature
        > .ms-nav__list
        > .ms-nav__list__item
        .ms-nav__list__item__link {
        font-size: 15px;
        padding-bottom: 0;
    }
}

@media screen and (min-width: $msv-breakpoint-xl) {
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover,
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded='true'],
    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link[aria-expanded='true'],
    .ms-nav.ms-nav__disable-top-menu
        > .ms-nav__list
        > .ms-nav__list__item
        > .ms-nav__feature
        > .ms-nav__list
        > .ms-nav__list__item
        .ms-nav__list__item__link {
        font-size: 18px;
    }

    .ms-nav.ms-nav__disable-top-menu > .ms-nav__list > .ms-nav__list__item {
        margin-left: 24px;
    }
}

@media screen and (min-width: $msv-breakpoint-xxl) {
    .ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__feature {
        padding: 3% 20% 3%;
    }
}

nav.ms-nav.ms-nav__disable-top-menu > .ms-nav__list {
    > .ms-nav__list__item:nth-last-child(-n+3) {
        display: none;
    }
}

nav.ms-nav.mobile-vp.parent > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    font-size: 14px;
    padding: 8px 16px;
    position: relative;
    text-transform: uppercase;
}

nav.ms-nav.mobile-vp.parent > .ms-nav__list > .ms-nav__list__item:nth-child(7) > .ms-nav__list__item__link {
    padding-top: 16px;
    &::before {
        border-top: 1px solid black;
        content: '';
        height: 1px;
        left: 16px;
        position: absolute;
        top: 0;
        width: calc(100% - 32px);
    }
}

nav.ms-nav.mobile-vp.parent > .ms-nav__list {
    padding-bottom: 8px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .ms-nav > .ms-nav__list > .ms-nav__list__item {
        background: #fff;
        > .ms-nav__list__item__button {
            float: none;
        }
    }
}


@media screen and (orientation: landscape) and (max-height: 840px) {
    .ms-nav.ms-nav__disable-top-menu {
        > .ms-nav__list {
            > .ms-nav__list__item {
                .ms-nav__feature {
                    padding: 2% 10%;
                    > .ms-nav__list {
                        > .ms-nav__list__item {
                            .ms-nav__list__item__link {
                                font-size: 14px;
                                min-height: unset;
                                line-height: 1rem;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
            > .ms-nav__list__item:nth-of-type(1), > .ms-nav__list__item:nth-of-type(5) {
                .ms-nav__feature {
                    .ms-nav__list.level-2 {
                        columns: 3;
                    }
                } 
            }
            > .ms-nav__list__item:nth-of-type(4) {
                .ms-nav__feature {
                    .ms-nav__list.level-2 {
                        columns: 4;
                    }
                } 
            }
        }
    }
}