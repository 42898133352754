$msv-details-heading-margin-bottom: 20px;
$msv-details-heading-margin-top: 20px;
$msv-details-text-margin-right: 5px;
$msv-details-order-information-full-width: 100%;
$msv-details-order-information-padding-bottom: 0px;
$msv-details-order-information-qr-code-margin-top: 20px;
$msv-details-order-information-qr-code-margin-bottom: 20px;
$msv-details-order-information-qr-code-block-width: 280px;
$msv-details-order-information-qr-code-block-height: 120px;
$msv-details-order-information-qr-code-block-bg-color: $msv-white;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-group-delivery-items-text-color: $msv-gray-500;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 81px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-margin: 0 12px 20px 0;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 81px;
$msv-details-order-summary-heading-margin-bottom: 40px;
$msv-details-order-summary-line-spacing: 25px;
$msv-details-order-summary-line-total-amount-padding-bottom: 22px;
$msv-details-order-summary-line-total-amount-margin-bottom: 25px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-margin: 20px 20px 20px 0;
$msv-details-payment-methods-margin-top: 48px;
$msv-details-payment-methods-heading-margin-bottom: 23px;
$msv-details-payment-methods-heading-margin-top: 68px;
$msv-details-payment-methods-line-space: 8px;
$msv-details-default-padding: 20px 0;
$msv-details-default-margin: 20px 0;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-main-container-margin: 0;
$msv-details-order-information-padding-top: 54px;
$msv-order-confirmation-groups-padding-top: 48px;
$msv-order-confirmation-groups-padding-bottom: 48px;
$msv-order-confirmation-address-phone-padding-right: 15px;
$msv-order-confirmation-address-phone-margin-top: 15px;
$msv-order-confirmation-payment-method-amount-margin-left: 4px;
$msv-order-confirmation-address-margin-bottom: 0;
$msv-order-confirmation-address-margin-top: 16px;
$msv-order-confirmation-pickup-date-timeslot-padding-top: 30px;
$msv-order-confirmation-pickup-date-timeslot-margin-bottom: 6px;
$msv-order-confirmation-pickup-date-timeslot-title-padding-bottom: 6px;
$ms-order-confirmation-deliverytype-grid-row: 40px;
$msv-order-confirmation-groups-padding-top-tablet: 10px;
$msv-order-confirmation-groups-padding-bottom-tablet: 20px;
$msv-order-confirmation-group-delivery-margin-top-t: 32px;

.ms-order-confirmation {
    width: $msv-width-full;
    margin: auto;
    position: relative;

    > div:nth-child(4).ms-order-confirmation__payment-methods {
        border: solid 1px #000;
        border-radius: 10px;
        padding: 20px;
        // .ms-order-confirmation__payment-methods-title {
        //     margin-top: 0;
        //     position: relative;
        //     float: left;
        //     width: 100%;

        //     // &::before {
        //     //     content: '';
        //     //     position: absolute;
        //     //     width: calc(100% + 40px);
        //     //     height: 1px;
        //     //     background: #000;
        //     //     left: -20px;
        //     //     bottom: -12px;
        //     // }
        // }
    }

    &__heading {
        @include font-heading-h1-l();
        color: var(--msv-font-primary-color);
        margin-bottom: $msv-details-heading-margin-bottom;
        margin-top: $msv-details-heading-margin-top;
        text-align: center;
    }

    .qrcode-screenreader-text {
        @include visually-hidden();
    }

    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;
        padding-top: $msv-details-order-information-padding-top;
        padding-top: 0;

        &-qr-code {
            align-items: center;
            background-color: $msv-details-order-information-qr-code-block-bg-color;
            display: none;
            height: $msv-details-order-information-qr-code-block-height;
            margin-top: $msv-details-order-information-qr-code-margin-top;
            margin-bottom: $msv-details-order-information-qr-code-margin-bottom;
            width: $msv-details-order-information-qr-code-block-width;

            @media only screen and (max-width: $msv-breakpoint-m) {
                width: $msv-width-full;
            }
        }

        &-channel-reference-id {
            @include font-body-regular-m();
            display: block;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            margin-top: $msv-details-order-summary-heading-margin-bottom;
            margin-top: 10px;
            color: $msv-black;
        }

        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            @include font-body-regular-m();
            display: block;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;

            span {
                @include font-body-bold-m();
            }
        }

        &-created-date {
            @include font-heading-h3-l();
            display: flex;
            align-items: center;
            text-transform: uppercase;
            margin-top: 0;
            
            &:before {
                display:none;        
            }
        }
        @media screen and (max-width: $msv-breakpoint-m) {
            &-created-date {
                position: relative;
                top: 0;
                display: block;
                margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
                font-size: $msv-mobile-title-font-size-xs;

                &:before {
                    font-size: 40px;
                    position: absolute;
                    top: -80px;
                    width: 100%;
                }
            }

            &-count {
                border-right: 1px solid $msv-black;
                padding-left: $msv-details-order-information-spacing;
                padding-right: $msv-details-order-information-spacing;
            }

            &-amount {
                padding-left: $msv-details-order-information-spacing;
            }
        }

        &__btn-keep-shopping {
            display: none;
        }

        &__sales-lines {
            display: flex;
            flex-wrap: wrap;
            padding: $msv-details-sale-lines-padding;

            &-shipping-items-label,
            &-picking-items-label,
            &-emailing-items-label {
                display: block;

                @include font-heading-h5-l();
            }

            &:first-child {
                border-top: none;
            }
        }

        &__groups {
            @include font-body-regular-m();
            border-bottom: 0.5px solid $msv-black;
            display: flex;
            flex-wrap: wrap;
        }

        &__group {
            &-deliveryType-pickup,
            &-deliveryType-ship,
            &-deliveryType-email {
                flex: 0 0 $msv-details-order-information-full-width;
                display: flex;
                flex-direction: column;
                border-top: none;
                padding-top: 0;
                padding-bottom: $msv-order-confirmation-groups-padding-bottom;
            }

            &-delivery {
                padding-right: $msv-details-sale-line-spacing;

                &-heading {
                    @include font-heading-h3-l();
                    margin-right: $msv-details-text-margin-right;
                    text-transform: uppercase;
                    width: 100%;
                    float: left;
                    padding-bottom: 24px;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        width: calc(100% + 40px);
                        height: 1px;
                        background: #000;
                        left: -20px;
                        bottom: 14px;
                    }

                    @media screen and (max-width: $msv-breakpoint-m) {
                        font-size: $msv-mobile-title-font-size-xs;
                    }
                }

                &-total-items {
                    @include font-heading-h2-l();
                    color: $msv-details-group-delivery-items-text-color;
                    margin-right: $msv-details-text-margin-right;
                }

                &-processing-label {
                    display: block;
                }
            }
        }

        &__tracking-link {
            text-decoration: underline;
        }

        &__sales-line {
            margin: $msv-details-sale-line-margin;

            .msc-cart-line {
                display: block;
                width: $msv-details-sale-line-width;

                &__product {
                    display: none;
                }

                &__product-image {
                    margin: 0;

                    .msc_image {
                        border: none;
                        box-sizing: border-box;
                    }
                }

                &__product-title {
                    @include font-body-regular-m();
                }

                &__quantity {
                    display: none;

                    .quantity-label {
                        @include font-body-regular-s();
                    }

                    .quantity-value {
                        color: $msv-gray-500;
                        display: inline;

                        @include font-body-regular-s();
                        margin-left: $msv-details-text-margin-right;
                    }
                }

                &__product-price,
                &__product-savings {
                    display: none;
                }

                &__quantity.single-quantity {
                    visibility: hidden;
                }

                &-item-product-discount {
                    display: none;
                    color: var(--msv-success-color);
                }
            }

            &-btn-buy-it-again {
                margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
                width: $msv-details-sale-line-buy-again-btn-width;

                @include secondary-button-light();
            }
        }

        &__order-summary {
            @include font-body-regular-m();
            padding: $msv-details-default-padding;
            width: 50%;

            &::after {
                clear: both;
            }

            &-heading {
                margin-bottom: $msv-details-order-summary-heading-margin-bottom;

                @include font-heading-h3-l();
                text-transform: capitalize;
                text-align: left;
                width: $msv-width-full;
                text-transform: uppercase;

                @media screen and (max-width: $msv-breakpoint-m) {
                    font-size: $msv-mobile-title-font-size-xs;
                }
            }

            &-line-subtotal,
            &-line-shipping,
            &-line-tax-amount {
                margin-bottom: $msv-details-order-summary-line-spacing;
                width: $msv-width-full;

                @include font-body-regular-m();
            }

            &-line-total-amount {
                border-top: 1px solid $msv-black;
                padding-top: $msv-details-order-summary-line-total-amount-padding-bottom;
                width: $msv-width-full;

                @include font-heading-h5-l();

                .ms-order-details__order-summary-label {
                    @include font-heading-h6-l();
                }
            }

            &-line-earned-points {
                .ms-order-details__order-summary-label {
                    @include font-heading-h6-l();
                }
            }

            &-price {
                float: right;
            }
        }

        &__help {
            @include font-body-regular-m();
            padding: $msv-details-default-padding;
            display: none;

            &-title {
                @include font-heading-h6-l();
                margin-bottom: $msv-details-help-heading-margin-bottom;
            }

            &-content-number {
                @include vfi();
            }
        }

        &__payment-methods {
            float: right;
            margin-top: $msv-details-payment-methods-margin-top;
            display: flex;
            flex-direction: column;
            width: $msv-width-quarter;

            &-title {
                @include font-heading-h5-l();
                text-transform: capitalize;
                margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
                margin-top: $msv-details-payment-methods-heading-margin-top;

                @media screen and (max-width: $msv-breakpoint-m) {
                    margin-top: auto;
                }
            }

            &-line {
                margin-bottom: $msv-details-payment-methods-line-space;

                @include font-body-regular-m();
            }

            &-card-amount,
            &-loyalty-amount,
            &-gift-card-amount {
                margin-left: $msv-order-confirmation-payment-method-amount-margin-left;
            }
        }

        &__address {
            @include font-body-regular-m();
            margin-bottom: $msv-order-confirmation-address-margin-bottom;
            margin-top: $msv-order-confirmation-address-margin-top;

            &-header {
                display: none;
            }

            &-city,
            &-threeletterisoregionname {
                &::before {
                    content: '\A';
                    white-space: pre;
                }
            }

            &-county {
                display: none;
            }

            &-name {
                display: block;
            }

            &-phone {
                display: block;

                &-label {
                    display: none;
                }

                &-icon {
                    padding-right: $msv-order-confirmation-address-phone-padding-right;

                    @include add-icon($msv-Phone);

                    &::before {
                        font-size: $msv-font-size-ml;
                        vertical-align: middle;
                    }
                }
            }
        }

        &__pickup-date-timeslot {
            padding-top: $msv-order-confirmation-pickup-date-timeslot-padding-top;
            margin-bottom: $msv-order-confirmation-pickup-date-timeslot-margin-bottom;

            &-title {
                @include font-heading-h6-l();
                padding-bottom: $msv-order-confirmation-pickup-date-timeslot-title-padding-bottom;
            }

            &-content {
                @include font-body-regular-m();
            }
        }

        &__alert {
            display: block;
            margin-bottom: $msv-order-details-alert-margin-bottom;
            margin-top: $msv-order-details-alert-margin-top;
        }

        @media screen and (min-width: $msv-container-min-width-m) {
            margin: $msv-main-container-margin;

            &__sales-lines {
                &-emailing-items-label {
                    margin-top: auto;
                }
            }

            &__address {
                &-phone {
                    margin-top: $msv-order-confirmation-address-phone-margin-top;
                }
            }

            &__groups {
                & > div:nth-child(2n) + div::before {
                    content: '';
                    display: block;
                    width: 200%;
                }
            }
        }

        @media screen and (max-width: calc($msv-breakpoint-m - 1)) {
            &__groups {
                display: block;

                &_delivery {
                    padding-right: 0;
                }
            }

            &__group-deliveryType-pickup {
                padding-left: 0;
            }

            &__payment-methods {
                align-items: flex-start;
            }

            &__sales-lines {
                width: $msv-width-full;

                &-shipping-items-label,
                &-picking-items-label {
                    padding-top: $msv-details-shipping-items-label-padding-top-mobile;
                }
            }

            &__order-summary,
            &__payment-methods,
            &__help {
                float: none;
                padding-right: 0;
                width: $msv-width-full;
                position: relative;
                margin: $msv-details-default-margin;
            }

            &__help,
            &__order-summary {
                border-top: 0.5px solid $msv-gray-50;
            }

            &__order-summary {
                &-heading,
                &-line-subtotal,
                &-line-shipping,
                &-line-tax-amount,
                &-line-total-amount {
                    max-width: $msv-width-full;
                }
            }

            &__btn-keep-shopping,
            &__sales-line-btn-buy-it-again {
                width: $msv-width-full;
            }

            .msc-cart-line__product-price {
                position: unset;
            }
        }

        @media screen and (max-width: calc($msv-breakpoint-l - 1)) {
            .ms-order-confirmation {

                &__group-delivery {
                    order: 3;
                    margin-top: $msv-order-confirmation-group-delivery-margin-top-t;
                }

                &__groups > div {
                    padding-top: $msv-order-confirmation-groups-padding-top-tablet;
                    padding-bottom: $msv-order-confirmation-groups-padding-bottom-tablet;
                }

                &__sales-lines {
                    order: 2;

                    &-shipping-items-label,
                    &-picking-items-label,
                    &-emailing-items-label {
                        order: 1;
                    }
                }

                &__sales-line {
                    margin-bottom: 0;
                }
            }
        }

        @media screen and (min-width: $msv-breakpoint-l) {
            .ms-order-confirmation {
                &__group {
                    &-deliveryType {
                        &-pickup,
                        &-ship,
                        &-email {
                            display: grid;
                            grid-template-columns: 3fr 1fr;
                            grid-template-rows: $ms-order-confirmation-deliverytype-grid-row;
                        }
                    }

                    &-delivery {
                        grid-row: 1/3;
                        grid-column: 2/3;
                    }
                }

                &__sales-lines {
                    grid-row: 2/3;
                    grid-column: 1/2;

                    &-shipping-items-label,
                    &-picking-items-label {
                        grid-row: 1/2;
                        grid-column: 1/2;
                    }
                }
            }
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            .ms-order-confirmation__payment-methods {
                &:last-child {
                    float: left;
                    width: 75%;
                    padding-right: 20%;
                }
            }

            .ms-order-confirmation__order-summary-line-total-amount {
                margin-bottom: $msv-details-order-summary-line-total-amount-margin-bottom;
            }
        }

        @include image($msv-order-empty-image-width);

        .msc-empty_image {
            background-size: 100%;
        }
    }

    &__group-deliveryType-pickup.salesStatus-processing {
        float: left;
        width: 100%;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: solid 1px #000;
    } 
        
    &__group-deliveryType-pickup.salesStatus-processing:last-child{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.cirrus {
    .ms-order-confirmation {
        .ms-order-confirmation__group-delivery {
            border: 0.5px solid $msv-black;
            border-radius: 10px;
            padding: 20px;
        }
    }
    .ms-order-confirmation__group-delivery-heading, .ms-order-confirmation__payment-methods-title {
        border-bottom: .5px solid $msv-black;
        display: block;
        font-family: 'Akzidenz-Light';
        font-size: 30px;
        margin: 0 -20px 20px;
        padding: 0 20px 20px;
        text-transform: uppercase;
    }
    .ms-order-confirmation__sales-line .msc-cart-line__product-image {
        .msc_image {
            border: none;
        }
    }
    .ms-order-confirmation__order-summary-heading {
        font-family: 'Akzidenz-Light';
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
    }
    .ms-order-confirmation {
        .ms-order-confirmation__group-deliveryType-ship {            
            border-top: none;
            padding-top: $msv-details-order-information-padding-bottom;            
        }       
    }

    .ms-order-confirmation__address {
        font-family: 'Akzidenz-Bold';

        &-name {
            width: 100%;
            float: left;
        }
    }

    .ms-order-confirmation__groups {
        border-top: 1px solid $msv-black;
        border-bottom: none;
        padding-top: 30px;
        float: left;
        width: 100%;
    }

    .ms-order-confirmation__order-summary-line-total-amount {
        border-top: 1px solid $msv-black;
        font-family: 'Akzidenz-Bold';
    }

    .ms-order-confirmation__sales-lines-shipping-items-label {
        font-family: 'Akzidenz-Bold';
    }
}


//new style
.cirrus .ms-order-confirmation .ms-order-confirmation__group-deliveryType-ship{
    float: left;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #000;
    margin-bottom: 30px;
}

.cirrus .ms-order-confirmation .ms-order-confirmation__group-delivery{
    width: 20%;
    float: right;
}

.ms-order-confirmation__sales-lines.ms-order-confirmation__sales-lines__selection-global-disabled{
    float: left;
    width: 80%;
    padding-top: 20px;
}

.ms-order-confirmation__sales-line.ms-order-confirmation__sales-line__selection-disabled.ms-order-confirmation__sales-line__selection-global-disabled.ms-order-confirmation__sales-line__available-product{
    float: left;
    margin-right: 20px;
}

.msc-cart-line__content{
	display: none;
}

.ms-order-confirmation__payment-methods{
    float: right;
    width: 20%;
    border-radius: 10px;
    border: solid 1px #000;
   
    &:last-child{
        width: 60%;
        float: left;
        padding: 20px;
    }
}

.cirrus .ms-order-confirmation__payment-methods-title{
    margin: 0;
    padding-top: 20px;
    float: left;
    width: 100%;
    left: 0;
    position: relative;
    margin-bottom: 20px;
}

.cirrus .ms-order-confirmation__payment-methods-line{
    padding: 20px;
}

.ms-order-confirmation__order-summary{
    float: left;
    width: 50%;
}

.cirrus .ms-order-confirmation__order-summary-heading{
    padding-bottom: 20px;
}


p.ms-order-confirmation__order-summary-line-subtotal,
p.ms-order-confirmation__order-summary-line-shipping,
p.ms-order-confirmation__order-summary-line-tax-amount,
p.ms-order-confirmation__order-summary-line-total-amount
 {
    float: left;
    width: 100%;
    padding: 10px 0;
}

span.ms-order-confirmation__order-summary-label {
    float: left;
    width: auto;
}

span.msc-price.ms-order-confirmation__order-summary-price {
    float: right;
    width: auto;
}

span.msc-price.ms-order-confirmation__payment-methods-gift-card-amount{
    padding-left: 5px;
}

@media screen and (max-width: $msv-breakpoint-m) {

    .cirrus {
        .ms-order-confirmation {
            display: table;
            max-width: calc(100% - 30px);

            &__heading {
                font-size: 50px;
            }

            &__group-deliveryType-pickup {
                display: flex;
                flex-wrap: wrap;

                .ms-order-confirmation__group-delivery{
                    order: 3;
                    min-width: 100%;
                }

                span.ms-order-confirmation__sales-lines-picking-items-label{
                    float: left;
                    order: 1;
                    min-width: 100%;
                }
                
                div.ms-order-confirmation__sales-lines {
                    order: 2;
                    margin-bottom: 15px;
                }
            }

            &__group-deliveryType-ship{
                display: flex;
                flex-direction: column;
            }

            &__sales-lines-shipping-items-label {
                order: 1;
                width: 100%;
            }

            &__sales-lines.ms-order-confirmation__sales-lines__selection-global-disabled{
                order: 2;
                width: 100%;
            }

            .ms-order-confirmation__group-delivery {
                width: 100%;
                order: 3;
                margin-top: 20px;
            }


            &__payment-methods {
                float: left;
                width: 100%;
                margin-bottom: 30px;
            }

            &__payment-methods:last-child {
                float: left;
                width: 100%;
            }
        }
    }
}