// Confirmed colors
$msv-black: #000000;
$msv-dark: #1a1a1c;
$msv-blue: #2266E3;
$msv-white: #FFFFFF;
$msv-red-10: #9A212A;
$msv-yellow: #FFFF00;

//cirrus gray color scale
$msv-gray-1-custom: #454546;
$msv-gray-2-custom: #5C5C5E;
$msv-gray-3-custom: #707174;
$msv-gray-4-custom: #808285;
$msv-gray-5-custom: #959799;
$msv-gray-6-custom: #a8aaad;
$msv-gray-7-custom: #bcbec0;
$msv-gray-8-custom: #d1d2d4;

//cirrus blue color scale
$msv-blue-1-custom: #0D1733;
$msv-blue-2-custom: #162646;
$msv-blue-3-custom: #1D3455;
$msv-blue-4-custom: #233F63;
$msv-blue-5-custom: #2D547E;
$msv-blue-6-custom: #335E8D;
$msv-blue-7-custom: #38679B;
$msv-blue-8-custom: #4279B8;
$msv-blue-9-custom: #005eb8;
$msv-blue-10-custom: #0154A4;


$msv-gray-10: $msv-gray-1-custom;
$msv-gray-20: $msv-gray-2-custom;
$msv-gray-30: $msv-gray-4-custom;
$msv-gray-40: $msv-gray-4-custom;
$msv-gray-50: $msv-gray-6-custom;
$msv-gray-60: $msv-gray-1-custom;
$msv-gray-70: $msv-gray-2-custom;
$msv-gray-75: $msv-gray-7-custom;
$msv-gray-80: $msv-gray-3-custom;
$msv-gray-100: $msv-gray-8-custom;
$msv-gray-120: $msv-gray-5-custom;
$msv-gray-130: $msv-gray-5-custom;
$msv-gray-140: $msv-gray-6-custom;
$msv-gray-160: $msv-gray-3-custom;
$msv-gray-200: $msv-gray-6-custom;
$msv-gray-210: $msv-gray-8-custom;
$msv-gray-300: $msv-gray-8-custom;
$msv-gray-400: $msv-gray-5-custom;
$msv-gray-500: $msv-gray-4-custom;
$msv-gray-800: $msv-gray-6-custom;
$msv-gray-900: $msv-gray-3-custom;
$msv-gray-910: $msv-gray-2-custom;
$msv-gray-920: $msv-gray-1-custom;
$msv-gray-930: $msv-gray-8-custom;
$msv-gray-940: $msv-gray-7-custom;
$msv-white-10: #EBEBEB;
$msv-white-20: #F5F5F5;

$msv-blue-10: $msv-blue-8-custom;
$msv-blue-20: $msv-blue-8-custom;
$msv-blue-30: $msv-blue-1-custom;
$msv-blue-40: $msv-blue-2-custom;
$msv-blue-50: $msv-blue-3-custom;
$msv-blue-60: $msv-blue-7-custom;
$msv-blue-70: $msv-blue-1-custom;
$msv-blue-80: $msv-blue-4-custom;
$msv-blue-90: $msv-blue-5-custom;
$msv-blue-100: $msv-blue-6-custom;

$msv-red: #A80000;
$msv-pink: #FFE7E7;
$msv-green-10: #107C10;
$msv-green-20: #DFF6DD;
$msv-green-30: #C8F1D4;
$msv-green-40: #3FF23F;
$msv-black-10: #333333;
$msv-brown: #600000;

$msv-transparent: transparent;

//Feedback colors
$cirrus-success: $msv-green-10;
$cirrus-instruction: $msv-blue;
$cirrus-alert: #FDB52B;
$cirrus-warning: #E65722;
$cirrus-error: $msv-red-10;


// Semantic colors
$msv-primary: $msv-black;
$msv-primary-hover: $msv-gray-70;
$msv-primary-pressed: $msv-gray-60;
$msv-secondary: $msv-gray-50;
$msv-secondary-hover: $msv-white-20;
$msv-text-color: $msv-gray-20;
$msv-error-color: $msv-red-10;

$msv-link-color: $msv-blue;
$msv-divider-color: $msv-gray-40;

// Text color
$msv-font-primary-color: $msv-gray-20;
$msv-font-secondary-color: $msv-white;

// Accent colors
$msv-accent-brand-color: $msv-gray-20;
$msv-accent-brand-alt-color: $msv-white;

// Stroke colors
$msv-stroke-line: $msv-gray-50;

// Events
$msv-button-primary-rest-color: $msv-primary;
$msv-button-primary-hover-color: $msv-primary-hover;
$msv-button-primary-pressed-color: $msv-gray-60;
$msv-button-primary-disabled-color: $msv-gray-50;

$msv-button-secondary-rest-color: $msv-white;
$msv-button-secondary-hover-color: $msv-white-20;
$msv-button-secondary-pressed-color: $msv-gray-10;
$msv-button-secondary-disabled-color: $msv-white;

// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-white};
    --msv-body-bg-color: #{$msv-white};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$msv-secondary-hover};

    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};
    --msv-font-disabled-color: #{$msv-gray-50};

    // Events
    --msv-button-primary-rest-color: #{$msv-primary};
    --msv-button-primary-hover-color: #{$msv-primary-hover};
    --msv-button-primary-pressed-color: #{$msv-gray-60};
    --msv-button-primary-disabled-color: #{$msv-gray-50};
    --msv-button-secondary-rest-color: #{$msv-white};
    --msv-button-secondary-hover-color: #{$msv-white-20};
    --msv-button-secondary-pressed-color: #{$msv-white-10};
    --msv-button-secondary-disabled-color: #{$msv-white};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
}
