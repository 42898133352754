$msv-primary-font-family: "Akzidenz-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
$msv-font-family-beth-ellen: BethEllen;
$msv-font-family-nothing-you-can-do: NothingYouCouldDo;
$msv-font-grotesk: Akzidenz Grotesk BE Regular,helvetica,sans-serif;
$msv-font-style-normal: normal;
$msv-font-assistant: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
$msv-font-allerta: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
$msv-font-cinzel: Times, "Times New Roman", serif;
$msv-font-segoe: "Segoe UI";

$msv-font-weight-bold: bold;
$msv-font-weight-normal: normal;
$msv-font-weight-light: 300;
$msv-font-weight-400: 400;
$msv-font-weight-500: 500;
$msv-font-weight-heavy: 600;
$msv-font-weight-700: 700;
$msv-text-size: 16px;

$msv-heading-text-size: 20px;
$msv-heading-line-height: 1.2;

// Generic font sizes for Cirrus theme (? confusing)
$msv-font-size-xs: 12px;
$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-ml: 20px;
$msv-font-size-l: 24px;
$msv-font-size-xl: 36px;
$msv-font-size-xxl: 48px;

// Generic line heights for Cirrus theme (? confusing)
$msv-line-height-xs: 1.3;
$msv-line-height-s: 1.3;
$msv-line-height-m: 1.3;
$msv-line-height-l: 1.3;
$msv-line-height-xl: 1.3;
$msv-line-height-xxl: 1.3;
$msv-line-height-xxxl: 1.3;

// Generic desktop title font sizes for Cirrus theme
$msv-title-font-size-xxs: 16px;
$msv-title-font-size-xs: 20px;
$msv-title-font-size-s: 28px;       // styleguide h1 fontsize mobile
$msv-title-font-size-m: 40px;       // styleguide h1 fontsize tablet
$msv-title-font-size-m-s: 59px;   
$msv-title-font-size-l: 59px;       // styleguide h1 fontsize desktop
$msv-title-font-size-xl: 59px;
$msv-title-font-size-xxl: 59px;
$msv-title-font-size-s-custom: 18px;

// Generic desktop title line heights for Cirrus theme
$msv-title-line-height-xs: 1.2;
$msv-title-line-height-s: 1.2;
$msv-title-line-height-m-s: 1.2;    // styleguide h1 lineheight mobile
$msv-title-line-height-m: 1.15;     // styleguide h1 lineheight tablet 
$msv-title-line-height-m-l: 1.1;   
$msv-title-line-height-l: 1.1;      // styleguide h1 lineheight desktop
$msv-title-line-height-xl: 1.1;
$msv-title-line-height-xxl: 1.1;

// Generic tablet title font sizes for Cirrus theme (? confusing)
$msv-tablet-title-font-size-xs: 20px;
$msv-tablet-title-font-size-s: 24px;
$msv-tablet-title-font-size-m: 32px;
$msv-tablet-title-font-size-l: 48px;
$msv-tablet-title-font-size-xl: 60px;
$msv-tablet-title-font-size-xxl: 80px;

// Generic tablet title line heights for Cirrus theme (? confusing)
$msv-tablet-title-line-height-xs: 24px;
$msv-tablet-title-line-height-s: 28px;
$msv-tablet-title-line-height-m: 40px;
$msv-tablet-title-line-height-l: 56px;
$msv-tablet-title-line-height-xl: 72px;
$msv-tablet-title-line-height-xxl: 100px;

// Generic mobile title font size for Cirrus theme (? confusing)
$msv-mobile-title-font-size-xs: 20px;
$msv-mobile-title-font-size-s: 24px;
$msv-mobile-title-font-size-m: 32px;
$msv-mobile-title-font-size-l: 36px;
$msv-mobile-title-font-size-xl: 40px;
$msv-mobile-title-font-size-xxl: 48px;

// Generic mobile title line heights for Cirrus theme (? confusing)
$msv-mobile-title-line-height-xs: 24px;
$msv-mobile-title-line-height-s: 28px;
$msv-mobile-title-line-height-m: 40px;
$msv-mobile-title-line-height-l: 48px;
$msv-mobile-title-line-height-xl: 50px;
$msv-mobile-title-line-height-xxl: 60px;

$msv-text-size: $msv-font-size-m;
$msv-text-line-height: 20px;

// Generic box shadow depths for Cirrus theme
$msv-depth4: 0 0.3px 0.9px rgba(0, 0, 0, 0.1), 0 1.6px 3.6px rgba(0, 0, 0, 0.13);
$msv-depth8: 0 0.6px 1.8px rgba(0, 0, 0, 0.1), 0 3.2px 7.2px rgba(0, 0, 0, 0.13);
$msv-depth16: 0 1.2px 3.6px rgba(0, 0, 0, 0.1), 0 6.4px 14.4px rgba(0, 0, 0, 0.13);
$msv-depth24: 0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22);

@mixin adventureworks-font(
    $font-weight: $msv-font-weight-normal,
    $font-size: $msv-font-size-s,
    $line-height: $msv-line-height-s,
    $text-transform: none,
    $font-family: $msv-primary-font-family,
    $color: $msv-font-primary-color,
    $font-style: $msv-font-style-normal
) {
    font-weight: $font-weight;
    font-size: $font-size;
    line-height: $line-height;
    text-transform: $text-transform;
    font-family: $font-family;
    color: $color;
    font-style: $font-style;
}

@mixin font-content($font-weight: $msv-font-weight-normal,
$font-size: $msv-font-size-s, $line-height: $msv-line-height-s, $text-transform: none) {
    @include adventureworks-font($font-weight, $font-size, $line-height, $text-transform);
}

@mixin font-content-underline($font-weight: $msv-font-weight-normal,
$font-size: $msv-font-size-s, $line-height: $msv-line-height-s) {
    text-decoration-line: underline;

    @include font-content($font-weight, $font-size, $msv-line-height-m);
}

@mixin font-content-s($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight);
}

@mixin font-content-s-uppercase($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-s, $msv-line-height-s, uppercase);
}

@mixin font-content-s-height-l($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-s, $msv-line-height-l);
}

@mixin font-content-m($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-m-underline($font-weight: $msv-font-weight-normal) {
    text-decoration-line: underline;

    @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-m-strikethrough($font-weight: $msv-font-weight-normal) {
    text-decoration-line: line-through;

    @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-l($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-l, $msv-line-height-l);
}

@mixin font-content-xl($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-xl, $msv-line-height-xl);
}

@mixin font-content-xxl($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-xxl, $msv-line-height-xxl);
}

@mixin font-glyph-standard() {
    font-size: $msv-icon-size;
}

@mixin font-glyph-small() {
    font-size: $msv-icon-size-small;
}

@mixin font-content-heading($font-weight: $msv-font-weight-heavy) {
    @include font-content($font-weight, $msv-heading-text-size, $msv-heading-line-height);
}

@mixin lineHeightFromFontSize($font-size) {
    @if $font-size == $msv-text-size {
        line-height: $msv-text-line-height;
    }
}

// -------------------H1------------------------------------
// H1 (desktop) BOLD, font-size = 59px & line-height = 1.1

@mixin font-heading-h1-l() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h1-font-size-l),
        $line-height: var(--msv-h1-line-height-l));
        letter-spacing: -.03em;
}


// H1 (tablet) BOLD, font-size = 40px & line-height = 1.15

@mixin font-heading-h1-m() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h1-font-size-m),
        $line-height: var(--msv-h1-line-height-m));
        letter-spacing: -.03em;
}

// H1 (small) BOLD, font-size = 28px & line-height = 1.2

@mixin font-heading-h1-s() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h1-font-size-s),
        $line-height: var(--msv-h1-line-height-s));
        letter-spacing: -.03em;
}

h1 {
    @include font-heading-h1-s();
    
    @media screen and (min-width: 768px){
        @include font-heading-h1-m();
    }

    @media screen and (min-width: 992px){
        @include font-heading-h1-l();
    }
}

// -------------------H2------------------------------------

// H2 (desktop) BOLD, font-size = 30px & line-height = 1.3
@mixin font-heading-h2-l() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h2-font-size-l),
        $line-height: var(--msv-h2-line-height-l));
}

// H2 (tablet) BOLD, font-size = 30px & line-height = 1.3
@mixin font-heading-h2-m() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h2-font-size-m),
        $line-height: var(--msv-h2-line-height-m));
}

// H2 (mobile) BOLD, font-size = 22px & line-height = 1.4
@mixin font-heading-h2-s() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h2-font-size-s),
        $line-height: var(--msv-h2-line-height-s));
}

h2 {
    @include font-heading-h2-s();
    
    @media screen and (min-width: 768px){
        @include font-heading-h2-m();
    }

    @media screen and (min-width: 992px){
        @include font-heading-h2-l();
    }
}

// -------------------H3------------------------------------

// H3 (desktop) BOLD, font-size = 24px & line-height = 1.3
@mixin font-heading-h3-l() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h3-font-size-l),
        $line-height: var(--msv-h3-line-height-l));
}

// H3 (tablet) BOLD, font-size = 24px & line-height = 1.3
@mixin font-heading-h3-m() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h3-font-size-m),
        $line-height: var(--msv-h3-line-height-m));
}

// H3 (mobile) BOLD, font-size = 21px & line-height = 1.4
@mixin font-heading-h3-s() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h3-font-size-s),
        $line-height: var(--msv-h3-line-height-s));
}

@mixin font-heading-h3-l-custom() {
    @include msv-akzidenz-light(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: $msv-tablet-title-font-size-m,
        $line-height: var(--msv-h3-line-height-l));
}

h3 {
    @include font-heading-h3-s();
    
    @media screen and (min-width: 768px){
        @include font-heading-h3-m();
    }

    @media screen and (min-width: 992px){
        @include font-heading-h3-l();
    }
}

// -------------------H4------------------------------------

// H4 (desktop) MEDIUM, font-size = 23px & line-height = 1.3
@mixin font-heading-h4-l() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h4-font-size-l-custom),
        $line-height: var(--msv-h4-line-height-l));
}

// H4 (tablet) MEDIUM, font-size = 23px & line-height = 1.3
@mixin font-heading-h4-m() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h4-font-size-m),
        $line-height: var(--msv-h4-line-height-m));
}

// H4 (mobile) MEDIUM, font-size = 19px & line-height = 1.4
@mixin font-heading-h4-s() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h4-font-size-s),
        $line-height: var(--msv-h4-line-height-s));
}

h4 {
    @include font-heading-h4-s();
    
    @media screen and (min-width: 768px){
        @include font-heading-h4-m();
    }

    @media screen and (min-width: 992px){
        @include font-heading-h4-l();
    }
}

// -------------------H5------------------------------------

// H5 (desktop) MEDIUM, font-size = 15px & line-height = 1.35
@mixin font-heading-h5-l() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h5-font-size-l),
        $line-height: var(--msv-h5-line-height-l));
        letter-spacing: 0.03em;
}

// H5 (tablet) MEDIUM, font-size = 15px & line-height = 1.35
@mixin font-heading-h5-m() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h5-font-size-m),
        $line-height: var(--msv-h5-line-height-m));
        letter-spacing: 0.03em;
}

// H5 (mobile) MEDIUM, font-size = 15px & line-height = 1.35
@mixin font-heading-h5-s() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h5-font-size-s),
        $line-height: var(--msv-h5-line-height-s));
        letter-spacing: 0.03em;
}

h4 {
    @include font-heading-h5-s();
    
    @media screen and (min-width: 768px){
        @include font-heading-h5-m();
    }

    @media screen and (min-width: 992px){
        @include font-heading-h5-l();
    }
}

// -------------------H6------------------------------------

// H6 (desktop) BOLD, font-size = 16px & line-height = 1.5
@mixin font-heading-h6-l() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h6-font-size-l),
        $line-height: var(--msv-h6-line-height-l));
}

// H6 (tablet) BOLD, font-size = 16px & line-height = 1.5
@mixin font-heading-h6-m() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h6-font-size-m),
        $line-height: var(--msv-h6-line-height-m));
}

// H6 (mobile) BOLD, font-size = 16px & line-height = 1.5
@mixin font-heading-h6-s() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-h6-font-size-s),
        $line-height: var(--msv-h6-line-height-s));
}

h6 {
    @include font-heading-h6-s();
    
    @media screen and (min-width: 768px){
        @include font-heading-h6-m();
    }

    @media screen and (min-width: 992px){
        @include font-heading-h6-l();
    }
}

// --------------BODY------------------------------------

// Body BOLD medium, font-size = 16px & line-height = 24px
@mixin font-body-bold-m() {
    @include msv-akzidenz-bold(
        $font-weight: $msv-font-weight-700,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-m),
        $line-height: var(--msv-body-line-height-m));

    color: var(--msv-body-font-color);
}

// Body BOLD small, font-size = 14px & line-height = 20px
@mixin font-body-bold-s() {
    @include msv-akzidenz-bold(
        $font-weight: $msv-font-weight-500,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-m),
        $line-height: var(--msv-body-line-height-s));

    color: var(--msv-body-font-color);
}

// Body BOLD extra small, font-size = 12px & line-height = 16px
@mixin font-body-bold-xs() {
    @include msv-akzidenz-bold(
        $font-weight: $msv-font-weight-700,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-xs),
        $line-height: var(--msv-body-line-height-xs));

    color: var(--msv-body-font-color);
}

// Body REGULAR medium, font-size = 16px & line-height = 24px
@mixin font-body-regular-m() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-m),
        $line-height: var(--msv-body-line-height-m));

    color: var(--msv-body-font-color);
}

p {
    @include font-body-regular-m();
}

// Body REGULAR small, font-size = 14px & line-height = 20px
@mixin font-body-regular-s() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-s),
        $line-height: var(--msv-body-line-height-s));

    color: var(--msv-body-font-color);
}

@mixin font-body-regular-s-custom() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-m),
        $line-height: var(--msv-body-line-height-s));

    color: var(--msv-body-font-color);
}

// Body REGULAR extra small, font-size = 12px & line-height = 16px
@mixin font-body-regular-xs() {
    @include msv-akzidenz-regular(
        $font-weight: $msv-font-weight-400,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-body-font-size-xs),
        $line-height: var(--msv-body-line-height-xs));

    color: var(--msv-body-font-color);
}

// Special call-outs, font-size = 100px & line-height = 120px
@mixin font-callout-bold-l() {
    @include msv-akzidenz-bold(
        $font-weight: $msv-font-weight-700,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-callout-font-size-l),
        $line-height: var(--msv-callout-line-height-l));

    color: var(--msv-body-font-color);
}

// Special call-outs, font-size = 60px & line-height = 72px
@mixin font-callout-bold-m() {
    @include msv-akzidenz-bold(
        $font-weight: $msv-font-weight-700,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-callout-font-size-m),
        $line-height: var(--msv-callout-line-height-m));

    color: var(--msv-body-font-color);
}

// Special call-outs, font-size = 40px & line-height = 50px
@mixin font-callout-bold-s() {
    @include msv-akzidenz-bold(
        $font-weight: $msv-font-weight-700,
        $font-style: $msv-font-weight-normal,
        $font-size: var(--msv-callout-font-size-s),
        $line-height: var(--msv-callout-line-height-s));

    color: var(--msv-body-font-color);
}

// Style presets
:root {
    // Headings H1
    --msv-h1-font-size-l: #{$msv-title-font-size-l};
    --msv-h1-font-size-m: #{$msv-title-font-size-m};
    --msv-h1-font-size-s: #{$msv-title-font-size-s};
    --msv-h1-line-height-l: #{$msv-title-line-height-l};
    --msv-h1-line-height-m: #{$msv-title-line-height-m};
    --msv-h1-line-height-s: #{$msv-title-line-height-s};

    // Headings H2
    --msv-h2-font-size-l: 30px;
    --msv-h2-font-size-m: 30px;
    --msv-h2-font-size-s: 22px;
    --msv-h2-line-height-l: 1.3;
    --msv-h2-line-height-m: 1.3;
    --msv-h2-line-height-s: 1.4;

    // Headings H3
    --msv-h3-font-size-l: 24px;
    --msv-h3-font-size-m: 24px;
    --msv-h3-font-size-s: 21px;
    --msv-h3-line-height-l: 1.3;
    --msv-h3-line-height-m: 1.3;
    --msv-h3-line-height-s: 1.4;

    // Headings H4
    --msv-h4-font-size-l: 23px;
    --msv-h4-font-size-m: 23px;
    --msv-h4-font-size-s: 19px;
    // cirrus font size applied for h4
    --msv-h4-font-size-l-custom: #{$msv-title-font-size-s-custom};
    --msv-h4-line-height-l: 1.3;
    --msv-h4-line-height-m: 1.3;
    --msv-h4-line-height-s: 1.4;

    // Headings H5
    --msv-h5-font-size-l: 15px;
    --msv-h5-font-size-m: 15px;
    --msv-h5-font-size-s: 15px;
    --msv-h5-line-height-l: 1.35;
    --msv-h5-line-height-m: 1.35;
    --msv-h5-line-height-s: 1.35;

    // Headings H6
    --msv-h6-font-size-l: 16px;
    --msv-h6-font-size-m: 16px;
    --msv-h6-font-size-s: 16px;
    --msv-h6-line-height-l: 1.5;
    --msv-h6-line-height-m: 1.5;
    --msv-h6-line-height-s: 1.5;

    // Special Callouts
    --msv-callout-font-size-l: #{$msv-title-font-size-xl};
    --msv-callout-font-size-m: #{$msv-tablet-title-font-size-xl};
    --msv-callout-font-size-s: #{$msv-mobile-title-font-size-xl};
    --msv-callout-line-height-l: #{$msv-title-line-height-xl};
    --msv-callout-line-height-m: #{$msv-tablet-title-line-height-xl};
    --msv-callout-line-height-s: #{$msv-mobile-title-line-height-xl};

    // Body
    --msv-body-font-color: #{$msv-black};
    --msv-body-font-size-xs: #{$msv-font-size-xs};
    --msv-body-font-size-s: #{$msv-font-size-s};
    --msv-body-font-size-m: #{$msv-font-size-m};
    --msv-body-font-size-l: #{$msv-font-size-l};
    --msv-body-font-size-xl: #{$msv-font-size-xl};
    --msv-body-font-size-xxl: #{$msv-font-size-xxl};
    --msv-body-line-height-xs: #{$msv-line-height-xs};
    --msv-body-line-height-s: #{$msv-line-height-s};
    --msv-body-line-height-m: #{$msv-line-height-m};

    // Weight
    --msv-font-weight-bold: #{$msv-font-weight-bold};
    --msv-font-weight-normal: #{$msv-font-weight-normal};
    --msv-font-weight-light: #{$msv-font-weight-light};
    --msv-font-weight-heavy: #{$msv-font-weight-heavy};
}

strong {
    font-family: "Akzidenz-BE-Bold", "Akzidenz-Regular", Helvetica, Arial, sans-serif;
    font-weight: 700;
}