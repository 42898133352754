$carousel-control-opacity: 1;
$carousel-control-transition: opacity 0.15s ease;
$carousel-indicator-width: 12px;
$carousel-indicator-height: 12px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 6px;
$carousel-indicator-transition: opacity 0.6s ease;
$carousel-indicator-margin-left-right: 6px;
$carousel-caption-width: 70%;
$carousel-control-icon-size: 21px;
$carousel-control-icon-width: 100%;
$carousel-transition-duration: 0.6s;
// Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-transition: transform $carousel-transition-duration ease-in-out;
$carousel-transition-base: all 0.2s ease-in-out;
$carousel-transition-fade: opacity 0.15s linear;
$carousel-transition-collapse: height 0.35s ease;
$carousel-enable-transitions: true;
$carousel-enable-prefers-reduced-motion-media-query: true;
$chevron-width-desktop: 48px;
$chevron-translate-mobile: translate(-50%, -50%) scale(.5);
$chevron-translate-desktop: translate(-13%, -10%) scale(1);

@mixin chevron-mobile {
    position: fixed;
    top: 53%;
    left: 50%;
  /* bring your own prefixes */
}

// Style presets
:root {
    // Indicators
    --msv-carousel-indicator-color: var(--msv-font-secondary-color);
    --msv-carousel-indicator-fill-color: var(--msv-font-secondary-color);

    // Flippers
    --msv-carousel-flipper-color: var(--msv-font-secondary-color);
}

@mixin carousel-transition($transition...) {
    @if $carousel-enable-transitions {
        @if length($transition) == 0 {
            transition: $carousel-transition-base;
        } @else {
            transition: $transition;
        }
    }

    @if $carousel-enable-prefers-reduced-motion-media-query {
        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}

.screen-reader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.active.msc-carousel__item__right {
    transform: translateX(100%);
}

.active.msc-carousel__item__left {
    transform: translateX(-100%);
}

.msc-carousel {
    position: relative;
    align-items: center;
    display: flex;
    z-index: 0;

    @media screen and (min-width: $msv-breakpoint-m) {
        margin-top: -36px;
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        margin-top: -50px;
    }

    .ms-content-block[data-m-layout='full-width'] {
        .ms-content-block__details {
            .ms-content-block__title {
                border-bottom: 1.5px solid $msv-white;
                font-family: 'Akzidenz-Light', sans-serif;
                @include font-heading-h1-l();
                margin-bottom: 12px;
                padding-bottom: 12px;
                width: unset;
                text-transform: uppercase;
                font-weight: normal;
            }
        }
        &.texttheme__dark {
            .ms-content-block__details {
                .ms-content-block__title {
                    border-color: var(--msv-content-block-textheme-dark);
                }
            }
        }
    }
    .pointer-event {
        touch-action: pan-y;
    }

    &__dismiss {
        background: $msv-black;
        border-color: $msv-black;
        color: var(--msv-font-secondary-color);
        position: absolute;
        top: 0;
        right: 0;

        &::before {
            @include msv-icon();
            content: $msv-Cancel;
            font-size: $msv-heading-text-size;
            position: unset;
            text-align: center;
            vertical-align: text-bottom;
            visibility: visible;
        }
    }

    &__inner {
        position: relative;
        width: 100%;
        overflow: hidden;

        @include clearfix();
    }

    &__item {
        height: 100%;
        position: relative;
        display: none;
        float: left;
        width: 100%;
        margin-right: -100%;
        backface-visibility: hidden;

        @include carousel-transition($carousel-transition);

        &.active,
        &__next,
        &__prev {
            display: block;
        }

        .hero {
            a {
                width: 100%;
            }
            .ms-content-block__image {
                width: 100%;
                &:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background: rgba(0, 0, 0, 0.5);
                    background: linear-gradient(90deg, rgba(1, 1, 1, 0) 0%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 1) 100%);
                    z-index: 0;
                }
            }
            .ms-content-block__details.withImage {
                z-index: 100;
            }
        }
    }

    &.carousel-fade {
        &__item {
            opacity: 0;
            transition-property: opacity;
            transform: none;
        }

        .msc-carousel__item.active,
        .msc-carousel__item__next.msc-carousel__item__left,
        .msc-carousel__item__prev.msc-carousel__item__right {
            z-index: 1;
            opacity: 1;
        }

        .active.msc-carousel__item__left,
        .active.msc-carousel__item__right {
            z-index: 0;
            opacity: 0;

            @include carousel-transition(0s $carousel-transition-duration opacity);
        }
    }

    &__control__prev,
    &__control__next {
        top: calc(50% - 24px);
        transition: none;
        position: absolute;
        bottom: 0;
        z-index: 16;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: $carousel-control-opacity;
        border: 1px solid #ddd;
        cursor: pointer;

        @include carousel-transition($carousel-control-transition);

        // Hover/focus state

        @include flipper-container-decoration();
    }

    &__control__prev {
        left: 0;
        justify-content: flex-start;
        margin-left: 2px;

        &__icon {
            @include add-carousel-arrow($msv-arrowLeft);
        }
    }

    &__control__next {
        justify-content: flex-end;
        margin-right: 2px;
        right: 0;
        cursor: pointer;

        &__icon {
            @include add-carousel-arrow($msv-arrowRight);
        }
    }

    &__caption {
        color: var(--msv-carousel-indicator-color);
        position: absolute;
        right: (100% - $carousel-caption-width) / 2;
        bottom: 20px;
        left: (100% - $carousel-caption-width) / 2;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
    }

    &__indicators {
        position: absolute;
        right: 0;
        bottom: 1rem;
        left: 0;
        z-index: 15;
        display: flex;
        justify-content: center;
        padding-left: 0; // override <ol> default
        // Use the .carousel-control's width as margin so we don't overlay those
        margin-right: $carousel-indicator-margin-left-right;
        margin-left: $carousel-indicator-margin-left-right;
        list-style: none;

        li {
            @include carousel-transition($carousel-indicator-transition);
            @include vfi-psuedo(after, 3px);
            border-radius: 50%;
            border: 1px solid var(--msv-carousel-indicator-color);
            background: transparent;
            opacity: 1;
            box-sizing: content-box;
            flex: 0 1 auto;
            width: $carousel-indicator-width;
            height: $carousel-indicator-height;
            margin-right: $carousel-indicator-spacer;
            margin-left: $carousel-indicator-spacer;
            text-indent: -999px;
            cursor: pointer;
            background-clip: padding-box;
        }

        .active {
            background: var(--msv-carousel-indicator-fill-color);
            opacity: 1;
        }

        div {
            display: none !important;
        }
    }

    &__control__prev__icon,
    &__control__next__icon {
        display: flex;
        justify-content: center;
        width: 30px;
        height: 58px;
        transform: scale(0.5);

        @media screen and (min-width: $msv-breakpoint-m){
            width: 48px;
            transform: scale(1);
        }
        
    }
}

.msc-carousel__item__next:not(.msc-carousel__item__left) {
    transform: translateX(100%);
}

.msc-carousel__item__prev:not(.msc-carousel__item__right) {
    transform: translateX(-100%);
}

.ms-interactive-feature-collection {
.ms-interactive-feature-collection__heading {
    .ms-interactive-feature__title {
        font-size: 40px;
        text-transform: uppercase;
    }
}
}
@media screen and (max-width: $msv-breakpoint-m) {    
    .msc-carousel {

        &__inner {
            color: var(--msv-font-secondary-color);
        }
    }
}

@media (max-width: $msv-breakpoint-l) {
    .msc-carousel {
        height: auto;
        &__item{
            .hero{
                .ms-content-block__image{
                    &:after{
                        background: rgba(0,0,0,0.5);
                    }
                }
            }
        }
        .ms-content-block[data-m-layout='full-width'] {
            height: inherit;
            .ms-content-block__details {
                text-align: center;
                padding: 20px 45px 20px 45px;
                background: #000000;
                pointer-events: none;
                height: inherit;
                justify-content: flex-end;
                &.textplacement__right {
                    text-align: center;
                }
                .ms-content-block__title {
                    @include font-heading-h1-m;
                    padding-bottom: 5px;
                    text-align: right;
                }
                .ms-content-block__text {
                    // font-size: 24px;                    
                    text-align: right;
                    margin-bottom: 30px;
                    margin-top: 0;
                    h3{
                        line-height: 28px;
                    }
                }
            }
        }
    }

    .ms-interactive-feature-collection{
        .ms-interactive-feature-collection__heading {
            .ms-interactive-feature__title{
                font-size: 30px;
            }
        }
    }

    .featured-categories{
        .ms-tile-list{
            .ms-tile-list__heading{
                .ms-tile-list__title{
                    font-size: 30px;
                }
            }
        }        
    }

    .ms-interactive-feature-collection__text {
        p {
            color: $msv-white;
        }
    }
}
