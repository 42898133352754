//style presets
:root {
    --msv-breadcrumb-item-font-color: var(--msv-font-primary-color);
}

.ms-breadcrumb {
    padding: 44px 0;

    @media screen and (min-width: $msv-breakpoint-l2) {
        padding-top: 0;
    }

    .ms-breadcrumb_list {
        display: flex;
        align-items: center;
        color: var(--msv-breadcrumb-item-font-color);
        list-style: none;
        padding: 0;
        flex-wrap: wrap;

        .ms-breadcrumb-back-button {
            text-decoration: underline;
            cursor: pointer;
            margin-right: 10px;
            color: $msv-black;

            @include add-icon($msv-ChevronLeft, before);

            &::before {
                padding-right: 5px;
            }
        }
    }

    .ms-breadcrumb_item {
        @include font-body-regular-m();
        color: var(--msv-breadcrumb-item-font-color);
        position: relative;
        margin: 0;
        padding-right: 5px;

        a[data-no-click="true"] {
            cursor: default;
            pointer-events: none;
        }

        .ms-breadcrumb_link {
            color:#3a3a3a;
            text-decoration: none;
            text-transform: capitalize;

            &:hover{
                text-decoration: underline;
            }
        }

        &.breadcrumb_item-current {
            // font-family: $msv-font-grotesk;
            // font-weight: 700;
            // font-size: 15px;
            // margin-top: -2px;

            .ms-breadcrumb_link {    
                text-decoration: none;
                color: #000;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}
