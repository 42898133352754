// Variables
$ms-pre-footer-padding-left: 5px;
$ms-pre-footer-padding-right: 5px;
$ms-pre-footer-padding-top: 36px;
$ms-pre-footer-padding-bottom: 36px;
$ms-pre-footer-tablet-padding-left: 5px;
$ms-pre-footer-tablet-padding-right: 5px;
$ms-pre-footer-item-margin-right: 20px;
$footer-item-padding-desktop: 20px;
$ms-main-footer-item-margin-right: 20px;
$footer-post-footer-height: 50px; //110px
$ms-text-block-margin: 24px;
$ms-text-block-margin-top: 4px;
$ms-main-footer-padding-bottom: 44px;
$ms-main-footer-padding-top: 24px;
$ms-main-footer-list-item-padding: 0;
$ms-accordion-margin: 15px;
$ms-accordion-padding-left: 0;
$ms-accordion-min-height: 85px;
$ms-post-footer-link-font-weight-400: 400;
$ms-mobile-post-footer-list-padding-left: 0;
$ms-mobile-post-footer-list-padding-right: 0;
$ms-post-footer-padding-top: 65px;
$ms-post-footer-padding-bottom: 55px;
$ms-post-footer-list-item-padding-left: 10px;
$ms-post-footer-list-item-padding-right: 10px;
$ms-post-footer-list-item-tablet-mobile-padding: 0 10px;
$ms-footer-social-media-margin-right: 10px;
$ms-footer-copyrights-height: 40px;
$ms-footer-footer-link-padding-bottom: 35px;
$ms-footer-footer-link-padding-top: 35px;
$ms-tablet-copyright-height: 40px;
$ms-footer-desktop-social-media-margin-right: 10px;
$ms-footer-subscribe-text-margin: 20px 0 28px 0;
$ms-footer-subscribe-submit-margin: 20px 0 20px 0;
$ms-footer-social-media-image-height: 36px;//30px
$ms-footer-logo-height: 28px;
$ms-footer-logo-width: 220px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 112px;
$footer-back-to-top-padding: 10px 0;
$footer-back-to-top-margin: 3px;
$footer-heading-icon-margin: 1px 0 0 8px;

:root {
    //Backgrounds
    --msv-footer-bg-color: var(--msv-bg-color);
    --msv-post-footer-bg-color: #{$msv-white};

    // Headings
    --msv-footer-heading-font-color: var(--msv-font-primary-color);

    //items
    --msv-footer-font-color-secondary: var(--msv-font-secondary-color);

    //Links
    --msv-footer-link-font-color: var(--msv-font-primary-color);

    //Border
    --msv-footer-border-bottom-color: #{$msv-secondary};

    //Accordion
    --msv-accordion-item-drawer-color: #{$msv-gray-20};
}

footer {
    background-color: var(--msv-footer-bg-color);
    margin-top: 100px;

    .ms-footer {
        padding: 0;
        border-top: 1px solid $msv-black;

        .post-footer-container {
            background: var(--msv-post-footer-bg-color);
            color: $msv-black;
            padding: 0 10px;
        }

        .footer-top-row {
            > .row {
                padding-top: 40px;
                ul.ms-footer__list.inner-pills-container {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;
                    text-align: center;
                    max-width: 170px;
                    margin: 0 auto;
                    .outline-pill-btn .ms-footer__link {
                        width: 100%;
                        justify-content: center;
                    }
                }
                li.ms-footer__list-item.post-footer {
                    margin: 0 auto;
                }
            }
        }

        .pre-footer-container{
            background: $msv-black; 
            padding: 0 120px;           
            .ms-footer{
                &__list{
                    float:left;
                    width: 100%;  
                    &.pre-footer{
                        width: auto;
                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);
                    }                
                }

                &__list-item{
                    float:left;
                    width: auto;

                    &.pre-footer{
                        float:left;
                        width: auto;
                        .call-contact{
                            text-align: left;                            
                            > div{
                                float: right;
                                position: relative;

                                float: right;
                                position: relative;
                                padding: 0 30px;

                                &:before{
                                    content:"\EFE9";
                                    width: 20px;
                                    height: 20px;                                
                                    position: absolute;
                                    top: 45%;
                                    left: 0;
                                    transform: translateY(-50%) scale(1,.75);
                                    font-family: "Remix Icon";
    
                                    -webkit-font-smoothing: antialiased;
                                    display: inline-block;
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    text-rendering: auto;
                                    line-height: 1;
                                    font-size: var(--msv-header-icon-font-size);
                                    vertical-align: text-bottom;
                                    text-align: center;
                                }
                            }
                            
                        }
                        .email-contact{
                            text-align: left;                            
                            > div{
                                float: left;
                                position: relative;

                                float: left;
                                position: relative;
                                padding: 0 25px;

                                &:before{
                                    content:"\EEF3";
                                    width: 20px;
                                    height: 20px;                                
                                    position: absolute;
                                    top: 45%;
                                    left: 0;
                                    transform: translateY(-50%);
                                    font-family: "Remix Icon";
    
                                    -webkit-font-smoothing: antialiased;
                                    display: inline-block;
                                    font-style: normal;
                                    font-variant: normal;
                                    font-weight: normal;
                                    text-rendering: auto;
                                    line-height: 1;
                                    font-size: var(--msv-header-icon-font-size);
                                    vertical-align: text-bottom;
                                    text-align: center;
                                }
                            }
                            
                        }
                    }
                    .ms-text-block{
                        color: $msv-white;
                    }
                }
            }
        }

        .ms-footer-logo {
            .msc-image-container {
                @include image($ms-footer-logo-width, $ms-footer-logo-height);
            }
        }

        .ms-accordion {
            height: 100%;

            .ms-accordion-item-section {
                margin-left: $ms-accordion-margin;
                margin-right: $ms-accordion-margin;
            }

            .drawer__button {
                width: 100%;
                background-color: var(--msv-footer-bg-color);
                border: none;
                outline: none;
                border-top: 0.5px solid var(--msv-footer-border-bottom-color);
                padding-left: $ms-accordion-padding-left;
                min-height: $ms-accordion-min-height;

                &[aria-expanded="true"] {
                    @include add-icon($msv-Remove, after);

                    &::after {
                        color: var(--msv-accordion-item-drawer-color);
                        line-height: $msv-line-height-s;
                        float: right;
                    }
                }

                &[aria-expanded="false"] {
                    @include add-icon($msv-Add, after);

                    &::after {
                        color: var(--msv-accordion-item-drawer-color);
                        line-height: $msv-line-height-s;
                        float: right;
                    }
                }

                &:focus {
                    border: 0.5px solid #{$msv-blue};
                }

                &:focus:not(:focus-visible) {
                    outline: none;
                }
            }

            .drawer__buttontext {
                float: left;

                @include font-body-bold-s();
            }

            .ms-footer__list-item {
                line-height: $msv-line-height-xl;
            }
        }

        .ms-footer__item::before {
            float: left;
            margin-top: $ms-text-block-margin-top;
        }

        .ms-footer__list {
            &.pre-footer {
                border-top: none;

                .ms-footer__list-item {
                    &.find-a-store {
                        &:first-child {
                            .ms-footer__heading__title-icon::before {
                                @include msv-icon();
                                content: $msv-location;
                                margin-top: 4px;
                            }
                        }
                    }

                    &.customer-service {
                        &:first-child {
                            .ms-footer__heading__title-icon::before {
                                @include msv-icon();
                                content: $msv-Phone;
                                margin-top: 4px;
                            }
                        }
                    }

                    &.need-help {
                        &:first-child {
                            .ms-footer__heading__title-icon::before {
                                @include msv-icon();
                                content: $msv-Chat;
                                margin-top: 4px;
                            }
                        }
                    }

                    &.general-info {
                        &:first-child {
                            .ms-footer__heading__title-icon::before {
                                @include msv-icon();
                                content: $msv-question-circle;
                                margin-top: 4px;
                            }
                        }
                    }
                }

                .ms-footer__list {
                    .ms-footer__list-item {
                        &:nth-child(2) {
                            margin-left: $ms-text-block-margin;
                        }
                    }
                }

                .ms-footer__heading {
                    display: flex;

                    &__title {
                        margin: $footer-heading-icon-margin;
                    }
                }
            }

            &.post-footer {
                .ms-footer__link {
                    @include font-body-regular-s();
                    color: var(--msv-footer-font-color-secondary);

                    @include vfi();
                }

                .ms-footer-copyright {
                    @include font-body-bold-s();
                    color: var(--msv-footer-font-color-secondary);
                }

                .ms-footer__list {
                    display: flex;
                }

                .ms-footer__list.ms-footer-links {
                    .ms-footer__list-item {
                        padding-left: $ms-post-footer-list-item-padding-left;
                        padding-right: $ms-post-footer-list-item-padding-right;
                    }

                    .ms-footer__list-item:nth-child(1) {
                        border-right: 1px solid var(--msv-footer-bg-color);
                    }
                }

                .ms-footer-social-media {
                    .ms-footer__link {
                        height: $ms-footer-social-media-image-height;
                        width: $ms-footer-social-media-image-height;

                        &__text {
                            display: none;
                        }
                    }

                    .ms-footer__item {
                        .msc-image-container {
                            @include image($ms-footer-social-media-image-height, $ms-footer-social-media-image-height);
                        }
                    }

                    .msc-main_image {
                        max-width: $ms-footer-social-media-image-height;
                        min-width: $ms-footer-social-media-image-height;
                        height: unset;
                    }
                }
            }
        }

        .ms-text-block {
            margin-top: 0;
        }

        .ms-subscribe {
            background: transparent;

            .ms-subscribe__details {
                color: var(--msv-footer-link-font-color);
                padding: 0;
                width: 100%;

                &__text {
                    font-weight: $ms-post-footer-link-font-weight-400;
                    margin: $ms-footer-subscribe-text-margin;
                    color: $msv-gray-20;
                }

                &__form {
                    display: block;

                    &__email {
                        border-bottom: 1px solid $msv-gray-50;
                        color: var(--msv-footer-link-font-color);
                        width: 100%;

                        &::placeholder {
                            color: $msv-gray-40;
                        }
                    }

                    &__submit {
                        @include secondary-button-light();

                        margin: $ms-footer-subscribe-submit-margin;
                    }
                }

                &__heading {
                    display: none;
                }
            }
        }

        .ms-back-to-top {
            @include card-button-with-icon-only();
            @include add-icon($msv-ChevronUp, before);

            position: fixed;
            opacity: 0;
            bottom: $footer-back-to-top-bottom;
            right: $footer-back-to-top-right;
            z-index: 1;

            &:hover,
            &:focus {
                opacity: 0;
                box-shadow: $msv-depth8;
                text-decoration: none;
            }

            &:focus {
                outline: 1px dashed $msv-white;
                border: none;
                outline-offset: -4px;
            }

            &::before {
                font-size: $msv-icon-size-xs;
            }
        }

        @media (min-width: $msv-breakpoint-l + 1) {
            font-weight: $msv-font-weight-normal;

            .ms-accordion {
                display: none;
            }

            .ms-footer__link {
                @include vfi();
                @include font-body-regular-s();
            }

            .ms-footer__list {
                &.pre-footer {
                    //padding-top: $ms-pre-footer-padding-top;
                    //padding-bottom: $ms-pre-footer-padding-bottom;
                    padding-top: 19px;
                    padding-bottom: 19px;

                    .ms-footer__item {
                        padding-top: 0;
                    }

                    .ms-footer__list-item {
                        .ms-footer__heading {
                            &__title {
                                @include font-heading-h6-l();
                                color: var(--msv-footer-heading-font-color);
                            }
                        }

                        &.pre-footer {
                            //width: calc(2 * #{$msv-column-width-s});
                            width: auto;
                            //margin-right: $ms-pre-footer-item-margin-right;
                            margin: 0;
                            display: inline-flex;
                            flex-direction: column;
                            &.contact-info {
                                h5 {
                                    a {
                                        font-size: 16px;
                                        text-transform: none;
                                    }
                                }
                            }
                        }
                    }
                }

                &.main-footer {
                    border-top: 0.5px solid var(--msv-footer-border-bottom-color);
                    padding-bottom: $ms-main-footer-padding-bottom;

                    .ms-footer__item {
                        padding-top: $footer-item-padding-desktop;
                    }

                    li.ms-footer__list-item {
                        width: calc(2 * #{$msv-column-width-s});
                        padding-top: $ms-main-footer-padding-top;
                        margin-right: $ms-main-footer-item-margin-right;
                        display: inline-flex;
                        flex-direction: column;

                        &:last-child {
                            width: calc(2.5 * #{$msv-column-width-s});
                        }

                        .ms-footer__heading__title {
                            @include font-body-bold-m();
                        }

                        ul {
                            display: inline;
                            border-top: none;

                            li.ms-footer__list-item {
                                width: 100%;
                                padding-top: $ms-main-footer-list-item-padding;
                            }
                        }
                    }
                }

                &.post-footer {
                    display: flex;
                    height: $footer-post-footer-height;
                    margin: 40px 0 40px;
                    justify-content: space-between;

                    .ms-footer__list-item.post-footer {
                        align-self: center;
                    }

                    .ms-footer__list {
                        display: flex;
                    }

                    .ms-footer-social-media {
                        .ms-footer__link {
                            margin-right: $ms-footer-social-media-margin-right;
                        }
                    }

                    .ms-footer__list.ms-footer-copyright,
                    .ms-footer__list.ms-footer-links {
                        height: $ms-footer-copyrights-height;

                        .ms-footer__list-item {
                            align-self: center;
                        }
                    }
                }
            }

            .footer-top-row {
                > .row {
                    flex-wrap: nowrap;
                    .outer-pills-container {
                        padding: 0;
                        margin: 0;
                    }
                    ul.ms-footer__list.inner-pills-container {
                        max-width: none;
                        flex-direction: row;
                    }
                    .col-12 {
                        &:first-of-type {
                            max-width: 75%;
                        }
                        &:last-of-type {
                            max-width: 25%;
                        }
                        .ms-footer__list-item.post-footer {
                            margin-left: auto;
                            margin-right: 0;
                            align-self: flex-start;
                        }
                    }
                }
                .ms-footer__list.post-footer {
                    margin: 0;
                    justify-content: flex-end;
                }
            }
        }

        @media (max-width: $msv-breakpoint-l) {         
            .ms-footer__link {
                @include font-body-regular-s();
                color: var(--msv-footer-link-font-color);
                line-height: $msv-line-height-xl;

                @include vfi();
            }

            .ms-footer__list {
                &.main-footer {
                    display: none;
                }

                &.pre-footer {
                    display: flex;
                    flex-flow: wrap;
                    padding-top: $ms-pre-footer-padding-top;
                    padding-right: $ms-pre-footer-tablet-padding-right;
                    padding-left: $ms-pre-footer-tablet-padding-left;

                    .ms-footer__list-item {
                        &.pre-footer:nth-child(n + 1) {
                            padding-bottom: $ms-pre-footer-padding-bottom;
                            width: 50%;
                        }
                    }

                    .ms-footer__heading {
                        &__title {
                            @include font-heading-h6-s();
                        }
                    }
                }

                &.post-footer {
                    padding-bottom: 20px;
                    padding-top: 30px;
                    padding-left: 0;
                    padding-right: 0;
                    display: flex;

                    .ms-footer__list-item.post-footer {
                        display: flex;
                        justify-content: center;
                        padding-left: 25%;
                        padding-right: 25%;
                        padding-top: 0;

                        &:first-child{
                            display: inline-block;
                            width: 70%;
                            width: 150px;
                            height: auto;
                            max-width: 150px;
                            padding-right: 0;
                            padding-top: 10px;
                            padding-left: 0;

                            img.msc-main_image{
                                min-width: 150px;
                                max-width: 150px;
                                height: auto;
                            }
                        }

                        &:last-child{
                            padding: 0;
                            width: auto;
                        }

                        .ms-footer-social-media{
                            padding-top: 0;
                            flex-wrap: wrap;
                            width: auto;
                            li.ms-footer__list-item{
                                &.ms-footer-social-media{
                                    padding-top: 0;
                                    a.ms-footer__link{
                                        width: 28px;
                                        height: auto;
                                        max-width: 28px;
                                        .msc-main_image{
                                            float: left;
                                            min-height: 28px;
                                            min-width: 28px;
                                            max-height: 28px;
                                            max-width: 28px;
                                        }
                                    }
                                }
                                &:last-child{
                                    a.ms-footer__link{
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }

                    .ms-footer__list.ms-footer-copyright {
                        height: $ms-tablet-copyright-height;

                        .ms-footer__list-item {
                            align-self: center;
                            width: 100%;
                        }
                    }

                    .ms-footer-social-media {
                        .ms-footer__link {
                            @include vfi();
                            margin-right: $ms-footer-desktop-social-media-margin-right;
                        }
                    }

                    .ms-footer__list.ms-footer-links {
                        height: $msv-line-height-m;
                        padding-bottom: $ms-footer-footer-link-padding-bottom;
                        padding-top: $ms-footer-footer-link-padding-top;

                        .ms-footer__list-item {
                            align-self: center;
                        }

                        .ms-footer__link {
                            line-height: $msv-line-height-xl;
                            padding: $ms-post-footer-list-item-tablet-mobile-padding;
                        }
                    }
                    
                }
            }

            .pre-footer-container{
                padding: 0;

                .ms-footer__list{
                    &.pre-footer{
                        width: 100%;
                        padding-top: 20px;
                        
                        li.ms-footer__list-item{
                            &.pre-footer {                                
                                min-width: 100%;

                                .call-contact{
                                    float:left;
                                    width: 100%;
    
                                    div{
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                    }
                                }
    
                                .email-contact{
                                    float:left;
                                    width: 100%;
    
                                    div{
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            .ms-subscribe {
                .ms-subscribe__details {
                    width: calc(4 * #{$msv-column-width-m});
                    margin: 0;
                }
            }
        }

        @media (max-width: $msv-breakpoint-m - 1) {
            .ms-accordion {
                .ms-accordion-item {
                    &__drawer {
                        .drawer__buttontext {
                            font-weight: 500;
                        }
                    }
                }
            }

            .ms-footer__list {
                .ms-footer__list-item.pre-footer:nth-child(n + 1) {
                    width: 100%;
                }

                &.pre-footer {
                    flex-direction: column;
                }

                &.post-footer {
                    .ms-footer__list-item.post-footer {
                        padding-left: $ms-mobile-post-footer-list-padding-left;
                        padding-right: $ms-mobile-post-footer-list-padding-right;
                    }
                }
            }
        }

        @media (min-width: $msv-breakpoint-m) {
            .ms-back-to-top[aria-hidden="false"] {
                opacity: 0.41;

                &:hover,
                &:focus {
                    opacity: 0.8;
                }
            }
        }
    }
}
