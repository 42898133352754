.default-container {
    .color-palette {
        table {
            td {
                border: 1px solid $msv-black;
                padding: 8px;
            }
        }
        //Cirrus gray color scale
        .cirrus-white {background: $msv-white;}
        .msv-gray-1-custom {background: $msv-gray-1-custom; color: $msv-white;}
        .msv-gray-2-custom {background: $msv-gray-2-custom; color: $msv-white;}
        .msv-gray-3-custom {background: $msv-gray-3-custom; color: $msv-white;}
        .msv-gray-4-custom {background: $msv-gray-4-custom; color: $msv-white;}
        .msv-gray-5-custom {background: $msv-gray-5-custom; color: $msv-white;}
        .msv-gray-6-custom {background: $msv-gray-6-custom;}
        .msv-gray-7-custom {background: $msv-gray-7-custom;}
        .msv-gray-8-custom {background: $msv-gray-8-custom;}
        .cirrus-black {background: $msv-black;}

        //Cirrus blue color scale
        .msv-blue-1-custom {background: $msv-blue-1-custom; color: $msv-white;}
        .msv-blue-2-custom {background: $msv-blue-2-custom; color: $msv-white;}
        .msv-blue-3-custom {background: $msv-blue-3-custom; color: $msv-white;}
        .msv-blue-4-custom {background: $msv-blue-4-custom; color: $msv-white;}
        .msv-blue-5-custom {background: $msv-blue-5-custom; color: $msv-white;}
        .msv-blue-6-custom {background: $msv-blue-6-custom; color: $msv-white;}
        .msv-blue-7-custom {background: $msv-blue-7-custom; color: $msv-white;}
        .msv-blue-8-custom {background: $msv-blue-8-custom; color: $msv-white;}

        //Cirrus Feedback & Alert colors
        .cirrus-instruction {background: $cirrus-instruction; color: $msv-white}
        .cirrus-error {background: $cirrus-error; color: $msv-white}
        .cirrus-alert {background: $cirrus-alert; color: $msv-black}
        .cirrus-warning {background: $cirrus-warning; color: $msv-white}
        .cirrus-success {background: $cirrus-success; color: $msv-white}
    }
}