.ms-order-lookup {

    h2 {
        padding: 1rem 0;
        text-align: center;
    }

    p {
        margin: 2rem 0;
    }

    .ms-order-lookup__details__form {
        margin: 1rem 0;
        .ms-order-lookup__details__form__fields__order-number-label,
        .ms-order-lookup__details__form__fields__email-label {
            font-family: "Akzidenz-Bold";
            font-weight: 500;
            font-style: normal;
            font-size: 16px;
            line-height: 1.3;
            display: block;
            margin-bottom: 8px;
            margin-top: 1rem;
            text-transform: uppercase;
        }
        input {
            font-family: "Akzidenz-Regular";
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 1.3;
            background: #fff;
            border: 0.5px solid #a8aaad;
            border-radius: 3px;
            box-sizing: border-box;
            display: block;
            height: 52px;
            outline: none;
            padding: 16px;
            -moz-appearance: textfield;
            width: 610px;
            max-width: 90%;
            border: 0.5px solid #000;
            border-radius: 10px;
        }
    }

    .ms-order-lookup__details__form__submit.msc-btn {
        font-family: "Akzidenz-Regular";
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        line-height: 1.3;
        align-items: center;
        border-radius: 100px;
        box-sizing: border-box;
        cursor: default;
        display: inline-flex;
        flex-direction: row;
        height: 44px;
        justify-content: center;
        letter-spacing: 0.1em;
        min-width: 165px;
        outline: none;
        overflow: hidden;
        padding: 12px 48px;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        background: var(--msv-primary-btn-light-bg-color);
        border: 1px solid var(--msv-primary-btn-light-border-color);
        color: var(--msv-primary-btn-light-font-color);
        transition: background-color .2s linear,stroke .2s,color .2s linear,filter .2s linear,-webkit-filter .2s linear;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .ms-order-lookup__details__form__submit.msc-btn.disabled {
        background-color: #a8aaad;
        color: #000;
    }

    .ms-order-lookup__registered-user {
        a {
            color: #000;
            margin-left: .25rem;
            margin-right: .25rem;
            text-decoration: underline;
        }
    }

}
