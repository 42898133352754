body {
    color: black;
    overflow-x: hidden;
    main{
        > .default-container{
           
        }
    }
}

.default-container {
    .example {
        padding: 32px;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .default-container.default-container__1.container{
        padding-top: 0;
    }
}